import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import AdditionalMaterial from "@/models/AdditionalMaterial";

const state = {};

const getters = {};

const actions = {
  async fetchAdditionalsMaterialByPreparation(_, preparationUuid) {
    const request =
      new socioGrpcClient.ssp_back.intervention.MaterialListRequest();

    const requestMetadata = {
      ...sspMetadata({
        filters: {
          preparation: preparationUuid,
        },
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.intervention.MaterialControllerPromiseClient.list(
        request,
        requestMetadata
      );
    const additionalMaterials = response.toObject();
    await AdditionalMaterial.insertOrUpdate({
      data: additionalMaterials.resultsList,
    });
    return additionalMaterials;
  },
  async updateAdditionalMaterial(_, materialData) {
    const excludeFields = ["$id"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.MaterialRequest,
      materialData,
      excludeFields
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.MaterialControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    const additionalMaterial = response.toObject();
    await AdditionalMaterial.insertOrUpdate({ data: additionalMaterial });
    return additionalMaterial;
  },
  async bulkUpdateAdditionalMaterial(_, materialsData) {
    const excludeFields = ["$id"];

    materialsData.materials.forEach((material, index) => {
      materialsData.materials[index] = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.ssp_back.intervention.MaterialRequest,
        material,
        excludeFields
      );
    });

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.MaterialUpdateValidationListRequest,
      materialsData
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.MaterialControllerPromiseClient.bulkUpdateValidation(
        request,
        sspMetadata()
      );
    const additionalMaterial = response.toObject();
    await AdditionalMaterial.insertOrUpdate({
      data: additionalMaterial.resultsList,
    });
    return additionalMaterial;
  },
  async createAdditionalMaterial(_, materialData) {
    const excludeFields = ["$id", "uuid"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.MaterialRequest,
      materialData,
      excludeFields
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.MaterialControllerPromiseClient.create(
        request,
        sspMetadata()
      );
    const additionalMaterial = response.toObject();
    await AdditionalMaterial.insert({ data: additionalMaterial });
    return additionalMaterial;
  },
  async destroyAdditionalMaterial(_, materialUuid) {
    const request =
      new socioGrpcClient.ssp_back.intervention.MaterialDestroyRequest();
    request.setUuid(materialUuid);
    await socioGrpcClient.ssp_back.intervention.MaterialControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await AdditionalMaterial.delete(materialUuid);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
