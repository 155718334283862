import Section from "@/models/Section";
const state = {
  sectionsCount: 0,
};

const mutations = {
  SET_SECTION_COUNT: (state, count) => {
    state.sectionsCount = count;
  },
};

const getters = {
  sectionList: () => {
    return Section.query().get();
  },
  getSectionsCount: (state) => {
    return state.sectionsCount;
  },
};

const actions = {
  async fetchSections() {},
  async createOrUpdateCurrentSection() {},
  async deleteCurrentSection() {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
