import AdditionalInformation from "@/models/AdditionalInformation";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { responseTojavascript } from "@/utils/utilsGrcpRest";
import { sspMetadata } from "@/utils/metadata";

const FIELDS_TO_EXCLUDE = ["$id"];

const state = {
  currentAdditionalInformationUuid: null,
};

const mutations = {
  SET_CURRENT_ADDITIONAL_INFORMATION_UUID: (state, preparationUuid) => {
    state.currentAdditionalInformationUuid = preparationUuid;
  },
};

const getters = {
  currentAdditionalInformation: (state) => {
    return (
      AdditionalInformation.query()
        .where("uuid", state.currentAdditionalInformationUuid)
        .first() || {}
    );
  },
};

const actions = {
  async retrieveAdditionalInformationByMission({ commit }, projectUuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention
        .AdditionalInformationRetrieveByProjectRequest,
      { projectUuid }
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.AdditionalInformationControllerPromiseClient.retrieveByProject(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response);

    AdditionalInformation.create({
      data: data,
    });

    commit("SET_CURRENT_ADDITIONAL_INFORMATION_UUID", data.uuid);
  },

  async createAdditionalInformation(_, additionalInformation) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.AdditionalInformationRequest,
      additionalInformation,
      FIELDS_TO_EXCLUDE
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.AdditionalInformationControllerPromiseClient.create(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response);

    await AdditionalInformation.insert({
      data: data,
    });
  },

  async updateAdditionalInformation(_, additionalInformation) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.AdditionalInformationRequest,
      additionalInformation,
      FIELDS_TO_EXCLUDE
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.AdditionalInformationControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response);

    await AdditionalInformation.update({
      where: additionalInformation.uuid,
      data: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
