import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildClient } from "./utils";

const projectModule = socioGrpcClient.ssp_back.projects;

export const accidentClient = buildClient(
  projectModule,
  projectModule.HistoricalAccidentControllerPromiseClient,
  {
    retrieveRequest: projectModule.AccidentRetrieveRequest,
    createRequest: projectModule.AccidentRequest,
    updateRequest: projectModule.AccidentUpdateRequest,
    destroyRequest: projectModule.AccidentDestroyRequest,
    listRequest: projectModule.AccidentListRequest,
    partialUpdateRequest: projectModule.AccidentPartialUpdateRequest,
  }
);
export const activityClient = buildClient(
  projectModule,
  projectModule.HistoricalActivityControllerPromiseClient,
  {
    retrieveRequest: projectModule.ActivityRetrieveRequest,
    createRequest: projectModule.ActivityRequest,
    updateRequest: projectModule.ActivityUpdateRequest,
    destroyRequest: projectModule.ActivityDestroyRequest,
    listRequest: projectModule.ActivityListRequest,
    partialUpdateRequest: projectModule.ActivityPartialUpdateRequest,
  }
);

export const pollutionClient = buildClient(
  projectModule,
  projectModule.HistoricalPollutionControllerPromiseClient,
  {
    retrieveRequest: projectModule.PollutionRetrieveRequest,
    createRequest: projectModule.PollutionRequest,
    updateRequest: projectModule.PollutionUpdateRequest,
    destroyRequest: projectModule.PollutionDestroyRequest,
    listRequest: projectModule.PollutionListRequest,
    partialUpdateRequest: projectModule.PollutionPartialUpdateRequest,
  }
);

export const productClient = buildClient(
  projectModule,
  projectModule.HistoricalProductControllerPromiseClient,
  {
    retrieveRequest: projectModule.ProductRetrieveRequest,
    createRequest: projectModule.ProductRequest,
    updateRequest: projectModule.ProductUpdateRequest,
    destroyRequest: projectModule.ProductDestroyRequest,
    listRequest: projectModule.ProductListRequest,
    partialUpdateRequest: projectModule.ProductPartialUpdateRequest,
  }
);

export const restrictionClient = buildClient(
  projectModule,
  projectModule.HistoricalRestrictionControllerPromiseClient,
  {
    retrieveRequest: projectModule.RestrictionRetrieveRequest,
    createRequest: projectModule.RestrictionRequest,
    updateRequest: projectModule.RestrictionUpdateRequest,
    destroyRequest: projectModule.RestrictionDestroyRequest,
    listRequest: projectModule.RestrictionListRequest,
    partialUpdateRequest: projectModule.RestrictionPartialUpdateRequest,
  }
);

export const ICPEClient = buildClient(
  projectModule,
  projectModule.HistoricalICPEControllerPromiseClient,
  {
    retrieveRequest: projectModule.ICPERetrieveRequest,
    createRequest: projectModule.ICPERequest,
    updateRequest: projectModule.ICPEUpdateRequest,
    destroyRequest: projectModule.ICPEDestroyRequest,
    listRequest: projectModule.ICPEListRequest,
    partialUpdateRequest: projectModule.ICPEPartialUpdateRequest,
  }
);

export const testimonyClient = buildClient(
  projectModule,
  projectModule.TestimonyControllerPromiseClient,
  {
    retrieveRequest: projectModule.TestimonyRetrieveRequest,
    createRequest: projectModule.TestimonyRequest,
    updateRequest: projectModule.TestimonyUpdateRequest,
    destroyRequest: projectModule.TestimonyDestroyRequest,
    listRequest: projectModule.TestimonyListRequest,
    partialUpdateRequest: projectModule.TestimonyPartialUpdateRequest,
  }
);
