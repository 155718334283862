import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildClient } from "./utils";

const projectModule = socioGrpcClient.ssp_back.projects;

export const vulnerabilityInformationClient = buildClient(
  projectModule,
  projectModule.VulnerabilityInformationControllerPromiseClient,
  {
    retrieveRequest: projectModule.VulnerabilityInformationRetrieveRequest,
    createRequest: projectModule.VulnerabilityInformationRequest,
    updateRequest: projectModule.VulnerabilityInformationUpdateRequest,
    destroyRequest: projectModule.VulnerabilityInformationDestroyRequest,
    listRequest: projectModule.VulnerabilityInformationListRequest,
    partialUpdateRequest:
      projectModule.VulnerabilityInformationPartialUpdateRequest,
  }
);
export const vulnerabilityUsageClient = buildClient(
  projectModule,
  projectModule.VulnerabilityUsageControllerPromiseClient,
  {
    retrieveRequest: projectModule.VulnerabilityUsageRetrieveRequest,
    createRequest: projectModule.VulnerabilityUsageRequest,
    updateRequest: projectModule.VulnerabilityUsageUpdateRequest,
    destroyRequest: projectModule.VulnerabilityUsageDestroyRequest,
    listRequest: projectModule.VulnerabilityUsageListRequest,
    partialUpdateRequest: projectModule.VulnerabilityUsagePartialUpdateRequest,
  }
);
export const BssWorkClient = buildClient(
  projectModule,
  projectModule.BssWorkControllerPromiseClient,
  {
    retrieveRequest: projectModule.BSSWorkRetrieveRequest,
    createRequest: projectModule.BSSWorkRequest,
    updateRequest: projectModule.BSSWorkUpdateRequest,
    destroyRequest: projectModule.BSSWorkDestroyRequest,
    listRequest: projectModule.BSSWorkListRequest,
    partialUpdateRequest: projectModule.BSSWorkPartialUpdateRequest,
  }
);
export const LithologyInformationClient = buildClient(
  projectModule,
  projectModule.LithologyInformationControllerPromiseClient,
  {
    retrieveRequest: projectModule.LithologyInformationRetrieveRequest,
    createRequest: projectModule.LithologyInformationRequest,
    updateRequest: projectModule.LithologyInformationUpdateRequest,
    destroyRequest: projectModule.LithologyInformationDestroyRequest,
    listRequest: projectModule.LithologyInformationListRequest,
    partialUpdateRequest:
      projectModule.LithologyInformationPartialUpdateRequest,
  }
);
export const GeologicalFormationsClient = buildClient(
  projectModule,
  projectModule.GeologicalFormationControllerPromiseClient,
  {
    retrieveRequest: projectModule.GeologicalFormationsRetrieveRequest,
    createRequest: projectModule.GeologicalFormationsRequest,
    updateRequest: projectModule.GeologicalFormationsUpdateRequest,
    destroyRequest: projectModule.GeologicalFormationsDestroyRequest,
    listRequest: projectModule.GeologicalFormationsListRequest,
    partialUpdateRequest:
      projectModule.GeologicalFormationsPartialUpdateRequest,
  }
);
export const NeighbourPluClient = buildClient(
  projectModule,
  projectModule.NeighbourPLUControllerPromiseClient,
  {
    retrieveRequest: projectModule.NeighbourPLURetrieveRequest,
    createRequest: projectModule.NeighbourPLURequest,
    updateRequest: projectModule.NeighbourPLUUpdateRequest,
    destroyRequest: projectModule.NeighbourPLUDestroyRequest,
    listRequest: projectModule.NeighbourPLUListRequest,
    partialUpdateRequest: projectModule.NeighbourPLUPartialUpdateRequest,
  }
);
