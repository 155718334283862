import FloorInformation from "@/models/investegatedEnvs/FloorInformation";

import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";

const FIELDS_TO_EXCLUDE = ["$id"];

const state = {};

const mutations = {};

const getters = {
  currentFloorInformation: (state, getters, rootState) => {
    const floorInformation = new FloorInformation();
    floorInformation.setProject(rootState.missions.currentMissionUuid);
    const result =
      FloorInformation.query()
        .where("project", rootState.missions.currentMissionUuid)
        .first() || floorInformation;
    return result;
  },
};

const actions = {
  async retrieveFloorInformationByMission() {
    try {
      const projectUuid = store.getters["missions/currentMissionUuid"];
      const request =
        new socioGrpcClient.ssp_back.intervention.FloorInformationRetrieveByProjectRequest();
      request.setProjectId(projectUuid);
      const response =
        await socioGrpcClient.ssp_back.intervention.FloorInformationControllerPromiseClient.retrieveByProject(
          request,
          sspMetadata()
        );

      await FloorInformation.create({
        data: response.toObject(),
      });
      return response.toObject();
    } catch (error) {
      console.log("error", error);
    }
  },

  async createOrUpdateFloorInformation(_, floorInformation) {
    let response = null;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.FloorInformationRequest,
      floorInformation,
      FIELDS_TO_EXCLUDE
    );
    if (!floorInformation.uuid || floorInformation.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.intervention.FloorInformationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      await FloorInformation.insert({
        data: response.toObject(),
      });
      return response.toObject();
    }
    response =
      await socioGrpcClient.ssp_back.intervention.FloorInformationControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await FloorInformation.update({
      where: floorInformation.uuid,
      data: response.toObject(),
    });
    return response.toObject();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
