import AmbientAirInformation from "@/models/investegatedEnvs/AmbientAirInformation";

import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";

const FIELDS_TO_EXCLUDE = ["$id", "$bottleType"];

const state = {};

const mutations = {};

const getters = {
  currentAmbientAirInformation: (state, getters, rootState) => {
    const ambientAirInformation = new AmbientAirInformation();
    ambientAirInformation.setProject(rootState.missions.currentMissionUuid);
    const result =
      AmbientAirInformation.query()
        .where("project", rootState.missions.currentMissionUuid)
        .first() || ambientAirInformation;
    return result;
  },
};

const actions = {
  async retrieveAmbientAirInformationByMission() {
    try {
      const projectUuid = store.getters["missions/currentMissionUuid"];
      const request =
        new socioGrpcClient.ssp_back.intervention.AmbientAirInformationRetrieveByProjectRequest();
      request.setProjectId(projectUuid);

      const response =
        await socioGrpcClient.ssp_back.intervention.AmbientAirInformationControllerPromiseClient.retrieveByProject(
          request,
          sspMetadata()
        );
      await AmbientAirInformation.create({
        data: response.toObject(),
      });
      return response.toObject();
    } catch (error) {
      console.log("error", error);
    }
  },

  async createOrUpdateAmbientAirInformation(_, ambientAirInformation) {
    let response = null;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.AmbientAirInformationRequest,
      ambientAirInformation,
      FIELDS_TO_EXCLUDE
    );
    if (
      !ambientAirInformation.uuid ||
      ambientAirInformation.uuid.includes("$")
    ) {
      response =
        await socioGrpcClient.ssp_back.intervention.AmbientAirInformationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      await AmbientAirInformation.insert({
        data: response.toObject(),
      });
      return response.toObject();
    }

    response =
      await socioGrpcClient.ssp_back.intervention.AmbientAirInformationControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    await AmbientAirInformation.update({
      where: ambientAirInformation.uuid,
      data: response.toObject(),
    });
    return response.toObject();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
