import { restDocumentClient } from "@/setup/restDocumentClient";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { Document } from "@socotec.io/socio-vue-components";
import { buildMetadata } from "@/utils/metadata";
import Tag from "@/models/Tag";
import { objToProtoStruct, structToJsObj } from "@/utils/utilsGrcpRest";
import { imageExtensions } from "@/constants/document";

const state = {
  documentSelected: [],
  documentsCount: 0,
  documentTablePageSize: 10,
};

const excludeFields = [
  "createdAt",
  "updatedAt",
  "docTypeData",
  "docStatusData",
];

const getters = {
  getDocuments: (state) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .limit(state.documentTablePageSize)
      .get();
  },
  getImages: () => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .where((doc) => {
        return imageExtensions.some((ext) => doc.name.includes(ext));
      })
      .get();
  },
  getDocumentsByType: () => (type) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .where("docType", type)
      .get();
  },
  getDocumentByUuid: () => (uuid) => {
    return Document.query().where("uuid", uuid).first();
  },
  getSelectedDocuments: (state) => {
    return state.documentSelected;
  },
  getDocumentsCount: (state) => {
    return state.documentsCount;
  },
  getDocumentTablePageSize: (state) => {
    return state.documentTablePageSize;
  },
  getTagsNames: () => {
    const tags = Tag.query().all();
    return tags.map((obj) => obj.name);
  },
};
const actions = {
  setSelectedDocument({ commit }, documents) {
    commit("SET_SELECTED_DOCUMENTS", documents);
  },
  async getDocumentUrlAndName(context, docCustomUuid) {
    const params = {
      docCustomUuid,
    };

    try {
      const file = await restDocumentClient.get(
        "/documents/get_document_url_and_name/",
        { params }
      );
      return { fileUrl: file.data.file_url, fileName: file.data.file_name };
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Fetch document by id
   * @param [_]
   * @param uuid
   * @returns {Promise<Document>}
   */
  async fetchDocumentByUuid(_, uuid) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomRetrieveRequest();

    request.setUuid(uuid);

    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendRetrieve(
        request,
        {}
      );
    const document = response.toObject();

    await Document.insert({ data: document });

    return document;
  },

  /**
   * Fetch document list
   * @context [commit]
   * @param metadata {Object}
   * @returns {Promise<void> || Array}
   */
  async fetchDocuments({ commit }, metadata) {
    const requestMetadata = {
      ...buildMetadata(metadata),
    };
    const request =
      new socioGrpcClient.document.document.DocumentCustomListRequest();
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendList(
        request,
        requestMetadata
      );

    let objs = [];
    for (const responseElement of response.getResultsList()) {
      const objReformated = structToJsObj(responseElement);
      objs.push(objReformated);
    }

    commit("SET_DOCUMENT_COUNT", response.toObject().count);
    return await Document.create({ data: objs });
  },
  async fetchDocumentsAndFileUrl({ dispatch, getters }, { metadata }) {
    const count = await dispatch("fetchDocuments", metadata);

    for (const img of getters["getImages"]) {
      const { fileUrl } = await dispatch("getDocumentUrlAndName", img.uuid);
      img.bimFileUrl = fileUrl;
      img.$save();
    }

    return count;
  },
  async fetchDocumentTags(_, params) {
    const request = new socioGrpcClient.document.document.TagListRequest();
    try {
      const requestMetadata = {
        ...buildMetadata(params.metadata),
      };

      const response =
        await socioGrpcClient.document.document.TagControllerPromiseClient.list(
          request,
          requestMetadata
        );

      const tags = await Tag.insert({
        data: response.toObject().resultsList,
      });

      return tags.tag;
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async uploadDocument(
    _,
    { file, fileName, document, documentCustom, callBack }
  ) {
    try {
      await socioGrpcClient.document.document.StandbyUploadControllerPromiseClient.utils.uploadDocument(
        document,
        documentCustom,
        file,
        fileName,
        callBack
      );
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateDocument(_, data) {
    data.metaDatas = objToProtoStruct(data.metaDatas);
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.document.document.DocumentFlatRequest,
      data,
      excludeFields
    );
    try {
      const response =
        await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.updateDocumentCustomExtend(
          request,
          {}
        );
      const resp = structToJsObj(response);
      await Document.update({ data: resp, where: resp.uuid });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async removeDocument(_, documentUuid) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomDestroyRequest();

    request.setUuid(documentUuid);

    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.destroy(
        request,
        {}
      );
    response.toObject();

    return await Document.delete(documentUuid);
  },
  async updateMultipleDocs(context, { data, documentIds }) {
    const request =
      new socioGrpcClient.document.document.DocumentCustomBulkUpdateDocumentsRequest();

    for (const documentId of documentIds) {
      const document =
        new socioGrpcClient.document.document.DocumentFlatRequest();
      document.setUuid(documentId);
      document.setDatetime(data.datetime);
      document.setSource(data.source);
      document.setTagsList(data.tagsList || []);
      request.addDocuments(document);
    }
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.bulkUpdateDocuments(
        request,
        {}
      );
    const resultsList = response.toObject().resultsList;
    await Document.update({ data: resultsList });
  },
  async createManualDocument(context, documentData) {
    documentData.metaDatas = objToProtoStruct(documentData.metaDatas);
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.document.document.DocumentFlatRequest,
      documentData
    );
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.createWithDocument(
        request,
        {}
      );
    await Document.insert({ data: response.toObject() });

    return response.toObject();
  },
};

const mutations = {
  SET_SELECTED_DOCUMENTS: (state, documents) => {
    state.documentSelected = documents;
  },
  RESET_TAGS: async () => {
    await Tag.deleteAll();
  },
  INCREMENT_DOCUMENT_COUNT: (state) => {
    ++state.documentsCount;
  },
  DECREASE_DOCUMENT_COUNT: (state) => {
    --state.documentsCount;
  },
  SET_DOCUMENT_COUNT: (state, count) => {
    state.documentsCount = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
