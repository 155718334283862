import store from "@/store";
import {
  historicalResearchClient,
  consultedDocumentsClient,
  BASIASBASOLClient,
} from "@/store/api/historical-research";
import HistoricalResearch from "@/models/historicalResearch/HistoricalResearch";
import { filterExcludedFields } from "@/utils/arrayUtils";
import { sspMetadata } from "@/utils/metadata";

const excludeFields = ["createdAt", "updatedAt"];

export const actions = {
  async fetchHistoricalResearchs() {
    const { resultsList: testimonies } = await historicalResearchClient.list({
      project: store.getters["missions/currentMissionUuid"],
    });
    await HistoricalResearch.create({
      data: testimonies,
    });
    return testimonies;
  },
  async createOrUpdateCurrentHistoricalResearch(
    { commit },
    historicalResearch
  ) {
    historicalResearch.project = store.getters["missions/currentMissionUuid"];
    let newHistoricalResearch = null;
    const filteredHistoricalResearch = filterExcludedFields(
      historicalResearch,
      excludeFields
    );

    if (
      !filteredHistoricalResearch.uuid ||
      filteredHistoricalResearch.uuid.includes("$")
    ) {
      newHistoricalResearch = await historicalResearchClient.create(
        filteredHistoricalResearch,
        sspMetadata()
      );
      if (newHistoricalResearch) {
        await HistoricalResearch.create({
          data: newHistoricalResearch,
        });
      }
    } else {
      newHistoricalResearch = await historicalResearchClient.partialUpdate(
        filteredHistoricalResearch,
        sspMetadata()
      );
      if (newHistoricalResearch) {
        await HistoricalResearch.update({
          where: newHistoricalResearch.uuid,
          data: newHistoricalResearch,
        });
      }
    }
    commit("SET_STORED_HISTORICAL_RESEARCH", newHistoricalResearch);
    return newHistoricalResearch;
  },
  async deleteCurrentHistoricalResearch(_, uuid) {
    await historicalResearchClient.destroy(uuid, sspMetadata());
    return await HistoricalResearch.delete(uuid);
  },
  async fetchConsultedDocuments(_, { metadata }) {
    const filters = metadata.filters || {};
    const pagination = metadata.pagination || {};
    const { resultsList: consultedDocuments } =
      await consultedDocumentsClient.list(filters, pagination);
    return consultedDocuments;
  },
  async createOrUpdateConsultedDocument(_, consultedDocument) {
    let newConsultedDocument = null;
    const filteredConsultedDocument = filterExcludedFields(
      consultedDocument,
      excludeFields
    );

    if (
      !filteredConsultedDocument.uuid ||
      filteredConsultedDocument.uuid.includes("$")
    ) {
      newConsultedDocument = await consultedDocumentsClient.create(
        filteredConsultedDocument,
        sspMetadata()
      );
    } else {
      newConsultedDocument = await consultedDocumentsClient.partialUpdate(
        filteredConsultedDocument,
        sspMetadata()
      );
    }
    return newConsultedDocument;
  },
  async deleteConsultedDocument(_, uuid) {
    await consultedDocumentsClient.destroy(uuid, sspMetadata());
  },

  async fetchBASIASBASOL(_, { metadata }) {
    const filters = metadata.filters || {};
    const pagination = metadata.pagination || {};
    const { resultsList: BASIASBASOL } = await BASIASBASOLClient.list(
      filters,
      pagination
    );
    return BASIASBASOL;
  },
  async createOrUpdateBASIASBASOL(_, BASIASBASOL) {
    let newBASIASBASOL = null;
    const filteredBASIASBASOL = filterExcludedFields(
      BASIASBASOL,
      excludeFields
    );
    if (!filteredBASIASBASOL.uuid || filteredBASIASBASOL.uuid.includes("$")) {
      newBASIASBASOL = await BASIASBASOLClient.create(
        filteredBASIASBASOL,
        sspMetadata()
      );
    } else {
      newBASIASBASOL = await BASIASBASOLClient.partialUpdate(
        filteredBASIASBASOL,
        sspMetadata()
      );
    }
    return newBASIASBASOL;
  },
  async deleteBASIASBASOL(_, uuid) {
    await BASIASBASOLClient.destroy(uuid, sspMetadata());
  },

  setStoredHistoricalResearch({ commit }, historicalResearch) {
    commit("SET_STORED_HISTORICAL_RESEARCH", historicalResearch);
  },
};

export const mutations = {
  SET_STORED_HISTORICAL_RESEARCH(state, historicalResearch) {
    state.storedHistoricalResearch = historicalResearch;
  },
};
export const getters = {};
export const state = {
  storedHistoricalResearch: new HistoricalResearch(),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
