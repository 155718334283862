import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import LithologicalLayer from "@/models/LithologicalLayer";
import { renameKeys } from "@/utils/utilsGrcpRest";

const LITHOLOGY_FIELDS_TO_EXCLUDE = ["$id", "createdAt"];

const LITHOLOGY_KEYS_MAP = {
  typesList: "types",
  colorsList: "colors",
};

const state = {};

const mutations = {};

const actions = {
  async fetchLithologicalLayer(_, { metadata }) {
    const request =
      new socioGrpcClient.ssp_back.intervention.LithologicalLayerListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.LithologicalLayerControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { resultsList } = response.toObject();
    await LithologicalLayer.insert({
      data: resultsList.map((lithologicalLayer) =>
        renameKeys(LITHOLOGY_KEYS_MAP, lithologicalLayer)
      ),
    });
  },

  async deleteLithologicalLayer(_, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.LithologicalLayerDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.LithologicalLayerControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await LithologicalLayer.delete(uuid);
  },

  async createLithologicalLayer(_, lithologicalLayer) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.LithologicalLayerRequest,
      lithologicalLayer,
      LITHOLOGY_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.LithologicalLayerControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    await LithologicalLayer.insert({
      data: renameKeys(LITHOLOGY_KEYS_MAP, response.toObject()),
    });

    return response.toObject().uuid;
  },

  async updateLithologicalLayer(_, lithologicalLayer) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.LithologicalLayerRequest,
      lithologicalLayer,
      LITHOLOGY_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.LithologicalLayerControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await LithologicalLayer.update({
      where: lithologicalLayer.uuid,
      data: renameKeys(LITHOLOGY_KEYS_MAP, response.toObject()),
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
