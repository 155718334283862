import SoilGazInformation from "../../models/investegatedEnvs/SoilGazInformation";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";

const FIELDS_TO_EXCLUDE = ["$id", "$bottleType"];

const state = {};

const mutations = {};

const getters = {
  currentSoilGazInformation: (state, getters, rootState) => {
    const soilGazInformation = new SoilGazInformation();
    soilGazInformation.setProject(rootState.missions.currentMissionUuid);
    const result =
      SoilGazInformation.query()
        .where("project", rootState.missions.currentMissionUuid)
        .first() || soilGazInformation;
    return result;
  },
};

const actions = {
  async retrieveSoilGazInformationByMission() {
    try {
      const projectUuid = store.getters["missions/currentMissionUuid"];
      const request =
        new socioGrpcClient.ssp_back.intervention.SoilGazInformationRetrieveByProjectRequest();
      request.setProjectId(projectUuid);

      const response =
        await socioGrpcClient.ssp_back.intervention.SoilGazInformationControllerPromiseClient.retrieveByProject(
          request,
          sspMetadata()
        );

      await SoilGazInformation.create({
        data: response.toObject(),
      });
      return response.toObject();
    } catch (error) {
      console.log("error", error);
    }
  },

  async createOrUpdateSoilGazInformation(_, soilGazInformation) {
    let response = null;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SoilGazInformationRequest,
      soilGazInformation,
      FIELDS_TO_EXCLUDE
    );
    if (!soilGazInformation.uuid || soilGazInformation.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.intervention.SoilGazInformationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      await SoilGazInformation.insert({
        data: response.toObject(),
      });
      return response.toObject();
    }
    response =
      await socioGrpcClient.ssp_back.intervention.SoilGazInformationControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await SoilGazInformation.update({
      where: soilGazInformation.uuid,
      data: response.toObject(),
    });
    return response.toObject();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
