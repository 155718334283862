import ExcavatedGroundsInformation from "@/models/investegatedEnvs/ExcavatedGroundsInformation";

import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";

const FIELDS_TO_EXCLUDE = ["$id"];

const state = {};

const mutations = {};

const getters = {
  currentExcavatedGroundsInformation: (state, getters, rootState) => {
    const excavatedGroundsInformation = new ExcavatedGroundsInformation();
    excavatedGroundsInformation.setProject(
      rootState.missions.currentMissionUuid
    );
    const result =
      ExcavatedGroundsInformation.query()
        .where("project", rootState.missions.currentMissionUuid)
        .first() || excavatedGroundsInformation;
    return result;
  },
};

const actions = {
  async retrieveExcavatedGroundsInformationByMission() {
    try {
      const projectUuid = store.getters["missions/currentMissionUuid"];
      const request =
        new socioGrpcClient.ssp_back.intervention.ExcavatedGroundsInformationRetrieveByProjectRequest();
      request.setProjectId(projectUuid);
      const response =
        await socioGrpcClient.ssp_back.intervention.ExcavatedGroundsInformationControllerPromiseClient.retrieveByProject(
          request,
          sspMetadata()
        );

      await ExcavatedGroundsInformation.create({
        data: response.toObject(),
      });
      return response.toObject();
    } catch (error) {
      console.log("error", error);
    }
  },

  async createOrUpdateExcavatedGroundsInformation(
    _,
    excavatedGroundsInformation
  ) {
    let response = null;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.ExcavatedGroundsInformationRequest,
      excavatedGroundsInformation,
      FIELDS_TO_EXCLUDE
    );
    if (
      !excavatedGroundsInformation.uuid ||
      excavatedGroundsInformation.uuid.includes("$")
    ) {
      response =
        await socioGrpcClient.ssp_back.intervention.ExcavatedGroundsInformationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      await ExcavatedGroundsInformation.insert({
        data: response.toObject(),
      });
      return response.toObject();
    }
    response =
      await socioGrpcClient.ssp_back.intervention.ExcavatedGroundsInformationControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    await ExcavatedGroundsInformation.update({
      where: excavatedGroundsInformation.uuid,
      data: response.toObject(),
    });
    return response.toObject();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
