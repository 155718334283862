import socioGrpcClient from "@/setup/socioGrpcClient";
import GlobalMission from "@/models/GlobalMission";
import { renameKeys } from "../../utils/utilsGrcpRest";

const state = {};

const getters = {};

const actions = {
  async fetchGlobalMissions() {
    const request =
      new socioGrpcClient.ssp_back.projects.GlobalMissionListRequest();

    const response =
      await socioGrpcClient.ssp_back.projects.GlobalMissionControllerPromiseClient.list(
        request,
        {}
      );
    const { resultsList } = response.toObject();
    const keysMap = {
      avalaibleElementaryMissionsList: "avalaibleElementaryMissions",
      mandatoryElementaryMissionsList: "mandatoryElementaryMissions",
    };
    const globalMissionsData = resultsList.map((globalMission) =>
      renameKeys(keysMap, globalMission)
    );

    return GlobalMission.insert({ data: globalMissionsData });
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
