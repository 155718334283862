import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { buildClient } from "./utils";
import { GRPC_NOT_FOUND_ERROR_CODE } from "@/utils/errorsCodesUtils";
const projectModule = socioGrpcClient.ssp_back.projects;

export const siteInformationClient = {
  ...buildClient(
    projectModule,
    projectModule.SiteInformationControllerPromiseClient,
    {
      createRequest: projectModule.SiteInformationRequest,
      updateRequest: projectModule.SiteInformationRequest,
    }
  ),

  getSiteInformationWithPlans: async (form = {}, filters = {}) => {
    const metadata = {
      ...buildMetadata({ filters }),
    };
    let response, request;
    try {
      request =
        new projectModule.SiteInformationGetSiteInformationWithPlansRequest(
          form
        );
      response =
        await projectModule.SiteInformationControllerPromiseClient.getSiteInformationWithPlans(
          request,
          sspMetadata(metadata)
        );
    } catch (error) {
      if (error && error.code === GRPC_NOT_FOUND_ERROR_CODE) {
        request = new projectModule.SiteInformationRequest();
        request.setProject(form[0]);
        response =
          await projectModule.SiteInformationControllerPromiseClient.create(
            request,
            sspMetadata(metadata)
          );
      }
    }
    if (response) {
      return response.toObject();
    }
  },
};

export const sectionClient = buildClient(
  projectModule,
  projectModule.SectionControllerPromiseClient,
  {
    createRequest: projectModule.SectionRequest,
    updateRequest: projectModule.SectionRequest,
    destroyRequest: projectModule.SectionRequest,
    listRequest: projectModule.SectionListRequest,
  }
);
export const cadasterClient = buildClient(
  projectModule,
  projectModule.CadasterControllerPromiseClient,
  {
    createRequest: projectModule.CadasterRequest,
    updateRequest: projectModule.CadasterRequest,
    destroyRequest: projectModule.CadasterRequest,
    listRequest: projectModule.CadasterListRequest,
  }
);
