import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z0-9]/g, (ltr, idx) =>
    idx > 0 ? `_${ltr.toLowerCase()}` : ltr.toLowerCase()
  );

/**
 * Builds a gRPC client with the provided gRPC module, controller, and request types.
 * The function creates a client object with methods for retrieving, creating, updating,
 * deleting, and listing records. Each method is only added to the client object if
 * the corresponding request type is provided.
 *
 * @param {Object} grpcModule - The gRPC module used to create the client.
 * @param {Object} controller - The gRPC controller used to send requests.
 * @param {Object} [options] - An object containing request types and other options.
 * @param {Object} [options.retrieveRequest=null] - The gRPC request type for retrieving records.
 * @param {Object} [options.createRequest=null] - The gRPC request type for creating records.
 * @param {Object} [options.updateRequest=null] - The gRPC request type for updating records.
 * @param {Object} [options.destroyRequest=null] - The gRPC request type for deleting records.
 * @param {Object} [options.listRequest=null] - The gRPC request type for listing records.
 * @returns {Object} A client object with methods for interacting with the gRPC server.
 */
export const buildClient = (
  grpcModule,
  controller,
  {
    retrieveRequest = null,
    createRequest = null,
    updateRequest = null,
    destroyRequest = null,
    listRequest = null,
    partialUpdateRequest = null,
  }
) => {
  /**
   * Retrieves a single record based on the provided form data.
   * The function converts the form data to a gRPC request, sends it to the controller,
   * and returns the response as a JavaScript object.
   *
   * @param {Object} form - The form data used to create the gRPC request.
   * @param {Object} [metadata={}] - Additional metadata to be sent with the request.
   * @returns {Promise<Object>} A promise that resolves with the response object.
   */
  const retrieve = async (form, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(retrieveRequest, form);
    const response = await controller.retrieve(request, metadata);
    return response.toObject();
  };

  /**
   * Creates a new record based on the provided form data.
   * The function converts the form data to a gRPC request, sends it to the controller,
   * and returns the response as a JavaScript object.
   *
   * @param {Object} form - The form data used to create the gRPC request.
   * @param {Object} [metadata={}] - Additional metadata to be sent with the request.
   * @returns {Promise<Object>} A promise that resolves with the response object.
   */
  const create = async (form, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(createRequest, form);
    const response = await controller.create(request, metadata);
    return response.toObject();
  };

  /**
   * Updates an existing record based on the provided form data.
   * The function converts the form data to a gRPC request, sends it to the controller,
   * and returns the response as a JavaScript object.
   *
   * @param {Object} form - The form data used to create the gRPC request.
   * @param {Object} [metadata={}] - Additional metadata to be sent with the request.
   * @returns {Promise<Object>} A promise that resolves with the response object.
   */
  const update = async (form, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(updateRequest, form);
    const response = await controller.update(request, metadata);
    return response.toObject();
  };

  /**
   * Updates an existing record based on the provided form data.
   * The function converts the form data to a gRPC request, sends it to the controller,
   * and returns the response as a JavaScript object.
   *
   * @param {Object} form - The form data used to create the gRPC request.
   * @param {Object} [metadata={}] - Additional metadata to be sent with the request.
   * @returns {Promise<Object>} A promise that resolves with the response object.
   */
  const partialUpdate = async (form, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
      partialUpdateRequest,
      form
    );
    request.setPartialUpdateFieldsList(
      Object.keys(form).map((key) => {
        const toSnake = camelToSnakeCase(key);
        return toSnake.endsWith("_list")
          ? toSnake.replace("_list", "")
          : toSnake;
      })
    );
    const response = await controller.partialUpdate(request, metadata);
    return response.toObject();
  };

  /**
   * Deletes a record based on the provided form data.
   * The function converts the form data to a gRPC request and sends it to the controller.
   * It does not return any data.
   *
   * @param {Object} form - The form data used to create the gRPC request.
   * @param {Object} [metadata={}] - Additional metadata to be sent with the request.
   * @returns {Promise<void>} A promise that resolves when the record has been deleted.
   */
  const destroy = async (uuid, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(destroyRequest, {
      uuid,
    });
    await controller.destroy(request, metadata);
  };

  /**
   * Retrieves a list of records based on the provided filters.
   * The function builds metadata from the filters and sends it to the controller.
   * It returns the response as a JavaScript object.
   *
   * @param {Object} filters - The filters used to build the metadata.
   * @returns {Promise<Object>} A promise that resolves with the response object.
   */
  const list = async (filters, pagination = {}) => {
    const metadata = {
      ...buildMetadata({
        filters,
        pagination,
      }),
    };
    const request = new listRequest();
    const response = await controller.list(request, metadata);
    return response.toObject();
  };
  const client = {
    controller,
    module: grpcModule,
  };
  if (retrieveRequest) client.retrieve = retrieve;
  if (createRequest) client.create = create;
  if (updateRequest) client.update = update;
  if (destroyRequest) client.destroy = destroy;
  if (listRequest) client.list = list;
  if (partialUpdateRequest) client.partialUpdate = partialUpdate;

  return client;
};
