import ConceptualDiagram from "@/models/Report/ConceptualDiagram";
import ProvisionalProgram from "../../models/Report/ProvisionalProgram";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { responseTojavascript } from "@/utils/utilsGrcpRest";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { removeObjectsFieldsEqualTo } from "@/utils/reportUtils";

const REPORT_CHAPTERS_FIELDS_TO_EXCLUDE = ["$id"];

const getters = {
  getCurrentConceptualDiagram: (state, getters, rootState, rootGetters) => {
    const currentQuestionnaire =
      rootGetters["report/getCurrentReportQuestionnaire"];
    const currentConceptualDiagram = ConceptualDiagram.query().where(
      "questionnaire",
      currentQuestionnaire.uuid
    );
    if (currentConceptualDiagram.exists()) {
      const conceptualDiagram = currentConceptualDiagram.get()[0];
      return conceptualDiagram;
    }
    return new ConceptualDiagram({ questionnaire: currentQuestionnaire.uuid });
  },
  getCurrentProvisionalProgram: (state, getters, rootState, rootGetters) => {
    const currentQuestionnaire =
      rootGetters["report/getCurrentReportQuestionnaire"];
    const currentProvisionalProgram = ProvisionalProgram.query().where(
      "questionnaire",
      currentQuestionnaire.uuid
    );
    if (currentProvisionalProgram.exists()) {
      const provisional_program = currentProvisionalProgram.get()[0];
      return provisional_program;
    }
    return new ProvisionalProgram({ questionnaire: currentQuestionnaire.uuid });
  },
};

const actions = {
  async fetchCurrentConceptualDiagram({ rootGetters }) {
    const request =
      new socioGrpcClient.ssp_back.report.ConceptualDiagramListRequest();
    const metadata = {
      ...buildMetadata({
        filters: {
          questionnaire:
            rootGetters["report/getCurrentReportQuestionnaire"].uuid,
        },
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.report.ConceptualDiagramControllerPromiseClient.list(
        request,
        metadata
      );
    const keysMap = {
      selectedZonesList: "selectedZones",
    };
    const { data, count } = responseTojavascript(response, keysMap);
    let conceptualDiagram = {};
    if (count) {
      conceptualDiagram = data[0];
      if (count > 1) {
        console.error(`We have found ${count} ConceptualDiagram, expected only one by Mission.
            Only '${conceptualDiagram.uuid}' will be considered.`);
      }
    }
    conceptualDiagram = removeObjectsFieldsEqualTo(conceptualDiagram, "");
    return await ConceptualDiagram.create({
      data: conceptualDiagram,
    });
  },

  async createOrUpdateCurrentConceptualDiagram(context, conceptualDiagram) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.report.ConceptualDiagramRequest,
      conceptualDiagram,
      REPORT_CHAPTERS_FIELDS_TO_EXCLUDE
    );

    let response = null;
    if (conceptualDiagram.uuid) {
      response =
        await socioGrpcClient.ssp_back.report.ConceptualDiagramControllerPromiseClient.update(
          request,
          sspMetadata()
        );
    } else {
      response =
        await socioGrpcClient.ssp_back.report.ConceptualDiagramControllerPromiseClient.create(
          request,
          sspMetadata()
        );
    }

    const newConceptualDiagram = response.toObject();
    return await ConceptualDiagram.insert({
      data: newConceptualDiagram,
    });
  },
  async fetchCurrentProvisionalProgram({ rootGetters }) {
    const request =
      new socioGrpcClient.ssp_back.report.ProvisionalProgramListRequest();
    const metadata = {
      ...buildMetadata({
        filters: {
          questionnaire:
            rootGetters["report/getCurrentReportQuestionnaire"].uuid,
        },
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.report.ProvisionalProgramControllerPromiseClient.list(
        request,
        metadata
      );
    const keysMap = {
      selectedZonesList: "selectedZones",
    };
    const { data, count } = responseTojavascript(response, keysMap);
    let provisionalProgram = {};
    if (count) {
      provisionalProgram = data[0];
      if (count > 1) {
        console.error(`We have found ${count} ConceptualDiagram, expected only one by Mission.
              Only '${provisionalProgram.uuid}' will be considered.`);
      }
    }
    provisionalProgram = removeObjectsFieldsEqualTo(provisionalProgram, "");

    return await ProvisionalProgram.create({
      data: provisionalProgram,
    });
  },

  async createOrUpdateCurrentProvisionalProgram(context, provisional_program) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.report.ProvisionalProgramRequest,
      provisional_program,
      REPORT_CHAPTERS_FIELDS_TO_EXCLUDE
    );

    let response = null;
    if (provisional_program.uuid) {
      response =
        await socioGrpcClient.ssp_back.report.ProvisionalProgramControllerPromiseClient.update(
          request,
          sspMetadata()
        );
    } else {
      response =
        await socioGrpcClient.ssp_back.report.ProvisionalProgramControllerPromiseClient.create(
          request,
          sspMetadata()
        );
    }

    const newProvisionalProgram = response.toObject();
    return await ProvisionalProgram.insert({
      data: newProvisionalProgram,
    });
  },
};

export default {
  namespaced: true,
  getters,
  actions,
};
