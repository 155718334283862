import store from "@/store";
import _ from "lodash";

export const buildMetadata = (metadata) => {
  return _.mapValues({ ...metadata }, (value) =>
    typeof value === "object" ? JSON.stringify(value) : value.toString()
  );
};

export const sspMetadata = (metadata = {}) => {
  const metadataCopy = buildMetadata(metadata);
  metadataCopy["project_id"] = store.getters["missions/currentMission"].uuid;
  return metadataCopy;
};
