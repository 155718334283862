import socioGrpcClient from "@/setup/socioGrpcClient";
import ElementaryMission from "@/models/ElementaryMission";

const state = {};

const getters = {};

const actions = {
  async fetchElementaryMissions() {
    const request =
      new socioGrpcClient.ssp_back.projects.ElementaryMissionListRequest();

    const response =
      await socioGrpcClient.ssp_back.projects.ElementaryMissionControllerPromiseClient.list(
        request,
        {}
      );
    return ElementaryMission.insert({ data: response.toObject().resultsList });
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
