import Analysis from "@/models/Analysis";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { responseTojavascript } from "@/utils/utilsGrcpRest";

const state = {
  analysisCount: 0,
};

const mutations = {
  SET_ANALYSIS_COUNT: (state, count) => {
    state.analysisCount = count;
  },
};

const getters = {
  analysisCount: (state) => {
    return state.analysisCount;
  },
};

const actions = {
  async listAllAnalysis(context, metadata = {}) {
    const request =
      new socioGrpcClient.ssp_back.intervention.AnalysisListRequest();

    const response =
      await socioGrpcClient.ssp_back.intervention.AnalysisControllerPromiseClient.utils.listAllObjects(
        request,
        buildMetadata(metadata)
      );

    Analysis.insert({
      data: response,
    });

    return response;
  },

  async fetchAnalysis({ commit }, metadata) {
    const request =
      new socioGrpcClient.ssp_back.intervention.AnalysisListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.AnalysisControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { data, count } = responseTojavascript(response);
    commit("SET_ANALYSIS_COUNT", count);

    Analysis.create({
      data: data,
    });
  },

  async deleteAnalysis(_, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.AnalysisDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.AnalysisControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await Analysis.delete(uuid);
  },

  async createAnalysis(_, analysis) {
    const excludeFields = ["$id"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.AnalysisRequest,
      analysis,
      excludeFields
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.AnalysisControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    await Analysis.insert({
      data: response.toObject(),
    });
  },
  async updateAnalysis(_, analysis) {
    const excludeFields = ["$id"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.AnalysisRequest,
      analysis,
      excludeFields
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.AnalysisControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await Analysis.update({
      where: analysis.uuid,
      data: response.toObject(),
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
