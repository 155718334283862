import socioGrpcClient from "@/setup/socioGrpcClient";
import { Role } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {};

const actions = {
  async fetchRoles(_, { serviceId, metadata }) {
    const requestMetadata = {
      filters: JSON.stringify({
        service_id: serviceId,
      }),
      ...metadata,
    };
    const request =
      new socioGrpcClient.contributors.contributors.RoleListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.RoleControllerPromiseClient.list(
        request,
        requestMetadata
      );

    return Role.insert({ data: response.toObject().resultsList });
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
