import ImmediateDanger from "@/models/ImmediateDanger";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";

const state = {};

const mutations = {};

const getters = {
  getImmediateDangersBySite: (state, getters, ImmediateDanger) => {
    return (
      ImmediateDanger.query()
        .where("ImmediateDanger", ImmediateDanger.uuid)
        .first() || new ImmediateDanger()
    );
  },
};

const actions = {
  async fetchImmediateDangers() {
    const request =
      new socioGrpcClient.ssp_back.projects.ImmediateDangerListRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.ImmediateDangerControllerPromiseClient.list(
        request,
        sspMetadata()
      );
    const responseList = response.toObject().resultsList;
    if (responseList.length) {
      await ImmediateDanger.create({
        data: responseList,
      });
    }
  },
  async createOrUpdateImmediateDanger(_, immediateDanger) {
    let response = null;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.ImmediateDangerRequest,
      immediateDanger
    );
    if (!immediateDanger.uuid || immediateDanger.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.ImmediateDangerControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      return await ImmediateDanger.insert({
        data: response.toObject(),
      });
    }
    response =
      await socioGrpcClient.ssp_back.projects.ImmediateDangerControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    return await ImmediateDanger.update({
      where: immediateDanger.uuid,
      data: response.toObject(),
    });
  },
  async deleteImmediateDanger(_, immediateDanger) {
    const request =
      new socioGrpcClient.ssp_back.projects.ImmediateDangerRequest();
    request.setUuid(immediateDanger.uuid);
    await socioGrpcClient.ssp_back.projects.ImmediateDangerControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await ImmediateDanger.delete({
      where: immediateDanger.uuid,
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
