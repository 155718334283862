import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import PurgeSoilGasMeasure from "@/models/Purge/PurgeSoilGasMeasure";
import PurgeUndergroundWaters from "@/models/Purge/PurgeUndergroundWaters";
import PurgeSoilGas from "@/models/Purge/PurgeSoilGas";
import PurgeUndergroundWatersMeasure from "@/models/Purge/PurgeUndergroundWatersMeasure";
import { isToday } from "date-fns";
import { renameKeys, responseTojavascript } from "@/utils/utilsGrcpRest";
import { SURVEY_TYPES } from "@/constants/survey";
import dayjs from "dayjs";

const PURGE_BASE_FIELDS_TO_EXCLUDE = [
  "$id",
  "createdAt",
  "duration",
  "estimatedQuantity",
  "effectiveQuantity",
  "estimatedDuration",
  "valid",
  "measures",
];
const PURGE_UNDERGROUND_WATERS_FIELDS_TO_EXCLUDE = [
  ...PURGE_BASE_FIELDS_TO_EXCLUDE,
  "waterLevelDepthFromSoil",
  "toolTotalDepthFromSoil",
];
const PURGE_MEASURE_BASE_FIELDS_TO_EXCLUDE = ["$id", "createdAt"];
const PURGE__SOIL_GAS_MEASURE_TO_EXCLUDE = [
  ...PURGE_MEASURE_BASE_FIELDS_TO_EXCLUDE,
  "isDioxygenRateValid",
  "isCarbonDioxideRateValid",
];
const PURGE_UNDERGROUND_WATERS_MEASURE_TO_EXCLUDE = [
  ...PURGE_MEASURE_BASE_FIELDS_TO_EXCLUDE,
  "isPhValid",
  "isConductivityValid",
];

const PURGE_KEYS_MAP = {
  materialsList: "materials",
  watersManagementList: "watersManagement",
};
// the rule is that each sample should be linked to a purge created on same date as the sample
const queryLatestSurveyPurge = (model, sample) => {
  const surveyUuid = sample.survey;
  return model
    .query()
    .where(({ survey, date }) => {
      const dateString = dayjs(date).format("YYYY-MM-DD");
      const sampleDateString = sample.datetime
        ? dayjs(sample.datetime).format("YYYY-MM-DD")
        : "";
      return (
        survey === surveyUuid &&
        ((sample.datetime && dateString === sampleDateString) ||
          (!sample.datetime && isToday(new Date(date))))
      );
    })
    .last();
};

const state = {};

const mutations = {};

const getters = {
  getPurgesFromSurvey: () => (type, uuid) => {
    if (type == SURVEY_TYPES.SOIL_GAS) {
      return PurgeSoilGas.query().where("survey", uuid).get();
    } else if (type == SURVEY_TYPES.UNDERGROUND_WATERS) {
      return PurgeUndergroundWaters.query().where("survey", uuid).get();
    }
  },
  purgeUndergroundWatersFromUuid: () => (uuid) => {
    return PurgeUndergroundWaters.query().find(uuid);
  },
  purgeSoilGasFromUuid: () => (uuid) => {
    return PurgeSoilGas.query().find(uuid);
  },
  purgeUndergroundWatersMeasureFromUuid: () => (uuid) => {
    return PurgeUndergroundWatersMeasure.query().find(uuid);
  },
  purgeSoilGasMeasureFromUuid: () => (uuid) => {
    return PurgeSoilGasMeasure.query().find(uuid);
  },
  latestSurveyPurgeSoilGas: () => (sample) => {
    return queryLatestSurveyPurge(PurgeSoilGas, sample);
  },

  latestSurveyPurgeUndergroundWaters: () => (sample) => {
    return queryLatestSurveyPurge(PurgeUndergroundWaters, sample);
  },
};

const actions = {
  async fetchPurgeUndergroundWaters(_, { metadata }) {
    const request =
      new socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { resultsList } = response.toObject();
    await PurgeUndergroundWaters.insert({
      data: resultsList.map((purge) => renameKeys(PURGE_KEYS_MAP, purge)),
    });
  },

  async deletePurgeUndergroundWaters(_, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention
        .PurgeUndergroundWatersDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await PurgeUndergroundWaters.delete(uuid);
  },

  async createPurgeUndergroundWaters(_, purge) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersRequest,
      purge,
      PURGE_UNDERGROUND_WATERS_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, PURGE_KEYS_MAP);

    await PurgeUndergroundWaters.insert({
      data,
    });

    return data;
  },

  async updatePurgeUndergroundWaters(_, purge) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersRequest,
      purge,
      PURGE_UNDERGROUND_WATERS_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, PURGE_KEYS_MAP);
    await PurgeUndergroundWaters.update({
      where: purge.uuid,
      data,
    });
  },

  async fetchPurgeSoilGas(_, { metadata }) {
    const request =
      new socioGrpcClient.ssp_back.intervention.PurgeSoilGasListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeSoilGasControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { resultsList } = response.toObject();
    await PurgeSoilGas.insert({
      data: resultsList.map((purge) => renameKeys(PURGE_KEYS_MAP, purge)),
    });
  },

  async deletePurgeSoilGas(_, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeSoilGasDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.PurgeSoilGasControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await PurgeSoilGas.delete(uuid);
  },

  async createPurgeSoilGas(_, purge) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeSoilGasRequest,
      purge,
      PURGE_BASE_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeSoilGasControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, PURGE_KEYS_MAP);
    await PurgeSoilGas.insert({
      data,
    });

    return data;
  },

  async updatePurgeSoilGas(_, purge) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeSoilGasRequest,
      purge,
      PURGE_BASE_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeSoilGasControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response, PURGE_KEYS_MAP);
    await PurgeSoilGas.update({
      where: purge.uuid,
      data,
    });
  },

  async fetchPurgeUndergroundWatersMeasures(_, { metadata }) {
    const request =
      new socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersMeasureListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersMeasureControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { resultsList: data } = response.toObject();
    await PurgeUndergroundWatersMeasure.insert({
      data,
    });
  },

  async deletePurgeUndergroundWatersMeasure(_, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention
        .PurgeUndergroundWatersMeasureDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersMeasureControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await PurgeUndergroundWatersMeasure.delete(uuid);
  },

  async createPurgeUndergroundWatersMeasure(_, purgeMeasure) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention
        .PurgeUndergroundWatersMeasureRequest,
      purgeMeasure,
      PURGE_UNDERGROUND_WATERS_MEASURE_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersMeasureControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    const data = response.toObject();
    await PurgeUndergroundWatersMeasure.insert({
      data,
    });

    return data.uuid;
  },

  async updatePurgeUndergroundWatersMeasure(_, purgeMeasure) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention
        .PurgeUndergroundWatersMeasureRequest,
      purgeMeasure,
      PURGE_UNDERGROUND_WATERS_MEASURE_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeUndergroundWatersMeasureControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await PurgeUndergroundWatersMeasure.update({
      where: purgeMeasure.uuid,
      data: response.toObject(),
    });
  },

  async fetchPurgeSoilGasMeasures(_, { metadata }) {
    const request =
      new socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureListRequest();
    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { resultsList: data } = response.toObject();
    await PurgeSoilGasMeasure.insert({
      data,
    });
  },

  async deletePurgeSoilGasMeasure(_, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await PurgeSoilGasMeasure.delete(uuid);
  },

  async createPurgeSoilGasMeasure(_, purgeMeasure) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureRequest,
      purgeMeasure,
      PURGE__SOIL_GAS_MEASURE_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    const data = response.toObject();
    await PurgeSoilGasMeasure.insert({
      data,
    });

    return data.uuid;
  },

  async updatePurgeSoilGasMeasure(_, purgeMeasure) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureRequest,
      purgeMeasure,
      PURGE__SOIL_GAS_MEASURE_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PurgeSoilGasMeasureControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await PurgeSoilGasMeasure.update({
      where: purgeMeasure.uuid,
      data: response.toObject(),
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
