import socioGrpcClient from "@/setup/socioGrpcClient";
import Material from "@/models/Materials";
import { buildMetadata } from "@/utils/metadata";
import store from "@/store";
import { renameKeys } from "@/utils/utilsGrcpRest";
import { get, isEmpty } from "lodash";

const MATERIALS_KEYS_MAP = {
  packsList: "packs",
  typologiesList: "typologies",
};

const state = {
  currentPreparation: null,
};

const getters = {
  getMaterials: () => {
    return Material.query().withAll().get();
  },
};

const actions = {
  async fetchMaterials(_, metadata = {}) {
    const uuids = get(metadata, "filters.uuids");
    if (!metadata?.filters || (uuids && isEmpty(uuids))) {
      console.error("Materials filters are required");
      return;
    }
    const request =
      new socioGrpcClient.materials.material_services.MaterialListRequest();

    const response =
      await socioGrpcClient.materials.material_services.MaterialControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );

    const agencyUuids = response
      .toObject()
      .resultsList.map((m) => m.agencyUuid);
    await store.dispatch("user/fetchAgencies", {
      filters: {
        uuids: agencyUuids,
      },
    });
    await Material.insert({
      data: response
        .toObject()
        .resultsList.map((material) =>
          renameKeys(MATERIALS_KEYS_MAP, material)
        ),
    });

    return response.toObject();
  },

  async fetchAllMaterials(_, metadata = {}) {
    const uuids = get(metadata, "filters.uuids");

    if (!metadata?.filters || (uuids && isEmpty(uuids))) {
      console.error("Materials filters are required");
      return;
    }
    metadata = buildMetadata(metadata);
    const request =
      new socioGrpcClient.materials.material_services.MaterialListRequest();
    const response =
      await socioGrpcClient.materials.material_services.MaterialControllerPromiseClient.utils.listAllObjects(
        request,
        metadata
      );
    await Material.insert({
      data: response.map((material) =>
        renameKeys(MATERIALS_KEYS_MAP, material)
      ),
    });
    return response;
  },
  async updateMaterial(_, material) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.materials.material_services.MaterialRequest,
      material,
      ["agency", "company"]
    );
    const response =
      await socioGrpcClient.materials.material_services.MaterialControllerPromiseClient.update(
        request,
        {}
      );
    const data = renameKeys(MATERIALS_KEYS_MAP, response.toObject());

    const dataInserted = await Material.update({
      where: data.uuid,
      data,
    });
    return dataInserted;
  },
  async createMaterial(_, material) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.materials.material_services.MaterialRequest,
      material,
      ["agency", "company"]
    );
    const response =
      await socioGrpcClient.materials.material_services.MaterialControllerPromiseClient.create(
        request,
        {}
      );
    const data = renameKeys(MATERIALS_KEYS_MAP, response.toObject());

    const dataInserted = await Material.insert({
      data,
    });
    return dataInserted;
  },
  async deleteMaterial(_, materialUuid) {
    const request =
      new socioGrpcClient.materials.material_services.MaterialRequest();

    request.setUuid(materialUuid);

    await socioGrpcClient.materials.material_services.MaterialControllerPromiseClient.destroy(
      request,
      {}
    );
    await Material.delete(materialUuid);
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
