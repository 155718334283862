import { Contributor, Role, utils } from "@socotec.io/socio-vue-components";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";

import { rolesNames } from "@/constants/contributors/roles.js";
const { CONTRIBUTORS_ENUMS } = utils.contributors;

const excludeFields = [
  "metadata",
  "$id",
  "createdAt",
  "updatedAt",
  "project",
  "role",
  "batchesList",
  "company",
  "isLocked",
];

const UPDATE_CONTRIBUTORS_TOTAL = "UPDATE_CONTRIBUTORS_TOTAL";
const UPDATE_CONTRIBUTORS_COUNT = "UPDATE_CONTRIBUTORS_COUNT";
const ADD_CONTRIBUTORS_COUNT = "ADD_CONTRIBUTORS_COUNT";

const state = {
  contributorsCount: 0,
  contributorsTotal: 0,
};

const getters = {
  getRoleByName: () => (roleName) => {
    return Role.query().where("name", roleName).first();
  },

  isMissionHasUserWithRole: (state, getters, rootState) => (role) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.missions.currentMissionUuid)
          .whereHas("role", (queryRole) => {
            queryRole.where("name", role);
          });
      })
      .exists();
  },

  getMissionSupervisor: (state, getters, rootState) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.missions.currentMissionUuid)
          .whereHas("role", (queryRole) => {
            queryRole.where("name", rolesNames.SUPERVISOR);
          });
      })
      .first();
  },

  getMissionProjectManager: (state, getters, rootState) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.missions.currentMissionUuid)
          .whereHas("role", (queryRole) => {
            queryRole.where("name", rolesNames.PROJECT_MANAGER);
          });
      })
      .first();
  },

  getMissionPilot: (state, getters, rootState) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.missions.currentMissionUuid)
          .whereHas("role", (query) => {
            query.where("name", rolesNames.PROJECT_MANAGER);
          });
      })
      .first();
  },

  getMissionProjectLead: (state, getters, rootState) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.missions.currentMissionUuid)
          .whereHas("role", (query) => {
            query.where("name", rolesNames.PROJECT_LEAD);
          });
      })
      .first();
  },

  getContributorByUuid: () => (uuid) => {
    const contributor = Contributor.query()
      .where("uuid", uuid)
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .first();
    return {
      ...contributor,
      role: contributor?.companyProjectRole?.role,
      company: contributor?.companyProjectRole?.company,
    };
  },

  getContributorsCount: (state) => {
    return state.contributorsCount;
  },
  getContributorsTotal: (state) => {
    return state.contributorsTotal;
  },
  getMissionContributors: (state, getters, rootState) => {
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query.where("projectId", rootState.missions.currentMissionUuid);
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .orderBy("userLastName")
      .get()
      .map((contributor) => {
        // Translation are made in SVC we cannot override them, this is a workaround
        // SVC component must be refactor to translation override
        if (contributor.companyProjectRole?.role?.name === "PROJECT_MANAGER") {
          contributor.companyProjectRole.role.name = "PILOT";
        }
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
        };
      });
  },
};

const actions = {
  async fetchContributors({ commit }, metadata) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorCompanyProjectRoleLightListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const contributors = response.getResultsList();
    const contributorsList = contributors.map((contributor) => {
      const metadata = contributor.getMetadata().toJavaScript();
      contributor = contributor.toObject();
      contributor.metadata = metadata;
      return contributor;
    });

    commit(UPDATE_CONTRIBUTORS_COUNT, response.getCount());
    return await Contributor.create({
      data: contributorsList,
      insert: ["role", "company", "companyProjectRole"],
    });
  },
  async createContributor({ commit }, contributor) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.contributors.contributors.ContributorInputRequest,
      contributor,
      excludeFields
    );
    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.create(
        request,
        {}
      );
    const newContributor = response.toObject();

    commit(ADD_CONTRIBUTORS_COUNT, 1);

    const dataInserted = await Contributor.insert({
      data: newContributor,
    });
    return dataInserted.contributor[0].uuid;
  },
  async updateContributor(context, contributor) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.contributors.contributors.ContributorInputRequest,
      contributor,
      excludeFields
    );
    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.update(
        request,
        {}
      );
    const newContributor = response.toObject();

    const dataInserted = await Contributor.insert({
      data: newContributor,
    });
    return dataInserted.contributor[0].uuid;
  },
  async deleteContributor({ commit }, contributor) {
    const request =
      new socioGrpcClient.contributors.contributors.ContributorDestroyRequest();
    request.setUuid(contributor.uuid);

    await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.destroy(
      request,
      {}
    );

    commit(ADD_CONTRIBUTORS_COUNT, -1);

    await Contributor.delete(contributor.uuid);
  },
  async searchContributor(context, filters) {
    const metadata = {
      filters: JSON.stringify(filters),
    };
    const request =
      new socioGrpcClient.contributors.contributors.ContributorCompanyProjectRoleLightListRequest();
    const response =
      await socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient.list(
        request,
        metadata
      );
    const contributors = response.toObject().resultsList;
    return contributors.map((contributor) => new Contributor(contributor));
  },
  updateContributorsTotal({ commit }, total) {
    commit(UPDATE_CONTRIBUTORS_TOTAL, total);
  },
};

const mutations = {
  [UPDATE_CONTRIBUTORS_COUNT]: (state, newTotal) => {
    state.contributorsCount = newTotal;
  },
  [UPDATE_CONTRIBUTORS_TOTAL]: (state, newTotal) => {
    state.contributorsTotal = newTotal;
  },
  [ADD_CONTRIBUTORS_COUNT]: (state, number) => {
    state.contributorsCount += number;
    state.contributorsTotal += number;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export { CONTRIBUTORS_ENUMS };
