import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildClient } from "./utils";

const projectModule = socioGrpcClient.ssp_back.projects;
export const historicalResearchClient = buildClient(
  projectModule,
  projectModule.HistoricalResearchControllerPromiseClient,
  {
    retrieveRequest: projectModule.HistoricalResearchRetrieveRequest,
    createRequest: projectModule.HistoricalResearchRequest,
    updateRequest: projectModule.HistoricalResearchUpdateRequest,
    destroyRequest: projectModule.HistoricalResearchDestroyRequest,
    listRequest: projectModule.HistoricalResearchListRequest,
    partialUpdateRequest: projectModule.HistoricalResearchPartialUpdateRequest,
  }
);

export const consultedDocumentsClient = buildClient(
  projectModule,
  projectModule.ConsultedDocumentsControllerPromiseClient,
  {
    retrieveRequest: projectModule.ConsultedDocumentsRetrieveRequest,
    createRequest: projectModule.ConsultedDocumentsRequest,
    updateRequest: projectModule.ConsultedDocumentsUpdateRequest,
    destroyRequest: projectModule.ConsultedDocumentsDestroyRequest,
    listRequest: projectModule.ConsultedDocumentsListRequest,
    partialUpdateRequest: projectModule.ConsultedDocumentsPartialUpdateRequest,
  }
);

export const BASIASBASOLClient = buildClient(
  projectModule,
  projectModule.BASIASBALControllerPromiseClient,
  {
    retrieveRequest: projectModule.BASIASBASOLRetrieveRequest,
    createRequest: projectModule.BASIASBASOLRequest,
    updateRequest: projectModule.BASIASBASOLUpdateRequest,
    destroyRequest: projectModule.BASIASBASOLDestroyRequest,
    listRequest: projectModule.BASIASBASOLListRequest,
    partialUpdateRequest: projectModule.BASIASBASOLPartialUpdateRequest,
  }
);
