import Activity from "@/models/SiteVisit/Activity.js";
import Building from "@/models/SiteVisit/Building.js";
import Incident from "@/models/SiteVisit/Incident.js";
import Landfill from "@/models/SiteVisit/Landfill.js";
import PersonMet from "@/models/SiteVisit/PersonMet.js";
import Questionnaire from "@/models/SiteVisit/Questionnaire.js";
import Storage from "@/models/SiteVisit/Storage.js";
import SuperStructure from "@/models/SiteVisit/SuperStructure.js";
import Advice from "@/models/SiteVisit/Advice.js";
import IdentifiedSource from "@/models/SiteVisit/IdentifiedSource.js";

import relatedToQuestionnaireModuleFactory from "@/composables/questionnaire/tree-form/site-visit/useRelatedToSiteVisitQuestionnaireStoreModule.js";
const buildingModule = relatedToQuestionnaireModuleFactory(Building);
const activityModule = relatedToQuestionnaireModuleFactory(Activity);
const incidentModule = relatedToQuestionnaireModuleFactory(Incident);
const landfillModule = relatedToQuestionnaireModuleFactory(Landfill);
const personMetModule = relatedToQuestionnaireModuleFactory(PersonMet);
const questionnaireModule = relatedToQuestionnaireModuleFactory(Questionnaire);
const storageModule = relatedToQuestionnaireModuleFactory(Storage);
const superStructureModule =
  relatedToQuestionnaireModuleFactory(SuperStructure);
const adviceModule = relatedToQuestionnaireModuleFactory(Advice);
const identifiedSourceModule =
  relatedToQuestionnaireModuleFactory(IdentifiedSource);

const getters = {
  // Related to Questionnaire getters
  ...buildingModule.getters,
  ...activityModule.getters,
  ...incidentModule.getters,
  ...landfillModule.getters,
  ...personMetModule.getters,
  ...questionnaireModule.getters,
  ...storageModule.getters,
  ...superStructureModule.getters,
  ...adviceModule.getters,
  ...identifiedSourceModule.getters,
};

const actions = {
  // Related to Questionnaire
  ...buildingModule.actions,
  ...activityModule.actions,
  ...incidentModule.actions,
  ...landfillModule.actions,
  ...personMetModule.actions,
  ...questionnaireModule.actions,
  ...storageModule.actions,
  ...superStructureModule.actions,
  ...adviceModule.actions,
  ...identifiedSourceModule.actions,
};

export default {
  namespaced: true,
  getters,
  actions,
};
