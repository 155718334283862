import Questionnaire from "@/models/SiteVisit/Questionnaire";
import Location from "@/models/SiteVisit/Location";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import { renameKeys } from "@/utils/utilsGrcpRest";

const getters = {
  getCurrentQuestionnaire: (state, getters, rootState, rootGetters) => {
    const currentMission = rootGetters["missions/currentMission"];
    const currentQuestionnaire = Questionnaire.query()
      .where("project", currentMission.uuid)
      .first();
    if (currentQuestionnaire) {
      return currentQuestionnaire;
    }
    return new Questionnaire({ project: currentMission.uuid });
  },
  getCurrentLocation: (state, getters) => {
    const currentVisitQuestionnaireUuid = getters.getCurrentQuestionnaire.uuid;

    const currentLocation = Location.query()
      .where("visitQuestionnaire", currentVisitQuestionnaireUuid)
      .first();
    if (currentLocation) {
      return currentLocation;
    }
    return new Location({ visitQuestionnaire: currentVisitQuestionnaireUuid });
  },
};

const actions = {
  // Questionnaire
  async fetchCurrentQuestionnaire({ rootGetters }) {
    const request =
      new socioGrpcClient.ssp_back.sitevisit.QuestionnaireListRequest();
    const metadata = {
      filters: JSON.stringify({
        project: rootGetters["missions/currentMission"].uuid,
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.sitevisit.QuestionnaireControllerPromiseClient.list(
        request,
        metadata
      );
    let questionnaire = {};
    const responseList = response.toObject().resultsList;
    if (responseList.length >= 1) {
      questionnaire = responseList[0];
      if (responseList.length > 1) {
        console.error(`We have found ${responseList.length} Questionnaire, expected only one by Mission.
          Only '${questionnaire.uuid}' will be considered.`);
      }
    } else if (responseList.length) {
      return null;
    }
    const keysMap = {
      siteDocumentsList: "siteDocuments",
      difficultWorkEnvironmentTypeList: "difficultWorkEnvironmentType",
    };
    questionnaire = renameKeys(keysMap, questionnaire);
    return await Questionnaire.create({
      data: questionnaire,
    });
  },

  async updateCurrentQuestionnaire(context, questionnaire) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.sitevisit.QuestionnaireRequest,
      questionnaire
    );

    let response = null;
    // Vuex auto fill uuid with "$" when not user provided.
    // We consider uuid field include "$" as it is empty
    response =
      await socioGrpcClient.ssp_back.sitevisit.QuestionnaireControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const questionnaireResponse = response.toObject();
    return await Questionnaire.create({
      data: questionnaireResponse,
    });
  },
  // Location extends Questionnaire by One to One
  async createOrUpdateCurrentLocation({ getters }, location) {
    let response = null;
    location.visitQuestionnaire = getters.getCurrentQuestionnaire.uuid;
    // Vuex auto fill uuid with "$" when not user provided.
    // We consider uuid field include "$" as it is empty
    if (!location.uuid || location.uuid.includes("$")) {
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.ssp_back.sitevisit.LocationRequest,
        location
      );
      response =
        await socioGrpcClient.ssp_back.sitevisit.LocationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
    } else {
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.ssp_back.sitevisit.LocationRequest,
        location
      );
      response =
        await socioGrpcClient.ssp_back.sitevisit.LocationControllerPromiseClient.update(
          request,
          sspMetadata()
        );
    }
    const newLocation = response.toObject();
    return await Location.create({
      data: newLocation,
    });
  },

  async fetchCurrentLocation({ getters }) {
    const request =
      new socioGrpcClient.ssp_back.sitevisit.LocationListRequest();
    const metadata = {
      filters: JSON.stringify({
        visit_questionnaire: getters.getCurrentQuestionnaire.uuid,
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.sitevisit.LocationControllerPromiseClient.list(
        request,
        metadata
      );
    const locationArray = response.toObject().resultsList;
    if (!locationArray.length) {
      return false;
    }
    const responseUniqueLocation = locationArray[0];
    return await Location.create({
      data: responseUniqueLocation,
    });
  },
};

export default {
  namespaced: true,
  getters,
  actions,
};
