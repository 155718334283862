import store from "@/store";
import {
  vulnerabilityInformationClient,
  vulnerabilityUsageClient,
  GeologicalFormationsClient,
  NeighbourPluClient,
  BssWorkClient,
  LithologyInformationClient,
} from "@/store/api/vulnerability";
import VulnerabilityInformation from "@/models/vulnerability/VulnerabilityInformation";
import VulnerabilityUsage from "@/models/vulnerability/VulnerabilityUsage";
import GeologicalFormation from "@/models/vulnerability/GeologicalFormation";
import NeighbourPlu from "@/models/vulnerability/NeighbourPlu";
import BssWork from "@/models/vulnerability/BssWork";
import LithologyInformation from "@/models/vulnerability/LithologyInformation";
import { sspMetadata } from "@/utils/metadata";

const clients = {
  geologicalFormation: GeologicalFormationsClient,
  bssWork: BssWorkClient,
  lithologyInformation: LithologyInformationClient,
  neighbourPlu: NeighbourPluClient,
  vulnerabilityInformation: vulnerabilityInformationClient,
  vulnerabilityUsage: vulnerabilityUsageClient,
};

const models = {
  geologicalFormation: GeologicalFormation,
  bssWork: BssWork,
  lithologyInformation: LithologyInformation,
  neighbourPlu: NeighbourPlu,
  vulnerabilityInformation: VulnerabilityInformation,
  vulnerabilityUsage: VulnerabilityUsage,
};

export const actions = {
  async fetchOrCreateVulnerability({ commit }, { type, mission, model }) {
    models[model].deleteAll();
    const client = clients[model];
    const vulnerabilitieslist = await client.list({
      project: mission.uuid,
      vulnerability_type: type,
    });
    let vulnerability = vulnerabilitieslist.resultsList[0];
    if (!vulnerability) {
      vulnerability = await client.create(
        {
          project: mission.uuid,
          vulnerabilityType: type,
        },
        sspMetadata()
      );
    }

    models[model].insertOrUpdate({
      data: vulnerability,
    });

    commit("SET_CURRENT_VULNERABILITY", vulnerability);
    return vulnerability;
  },

  async updateVulnerability(_, { form, model }) {
    delete form.createdAt;
    delete form.updatedAt;
    const data = await clients[model].partialUpdate(form, sspMetadata());
    models[model].insertOrUpdate({
      data: data,
    });
  },

  async fetchVulnerabilityRelatedData(_, { model }) {
    const client = clients[model];
    const modelClass = models[model];
    await modelClass.deleteAll();
    const { resultsList: items } = await client.list({
      vulnerability:
        store.getters["vulnerability/getCurrentVulnerability"].uuid,
    });
    if (!items.length) {
      console.log(`No ${model.toLowerCase()} found`);
      return null;
    }
    await modelClass.create({
      data: items,
    });
    return items;
  },

  async updateOrCreateVulnerabilityRelatedModel(_, { form, model }) {
    const client = clients[model];
    const modelClass = models[model];

    if (form.uuid) {
      const updatedItem = await client.partialUpdate(form, sspMetadata());
      if (updatedItem) {
        await modelClass.update({
          where: updatedItem.uuid,
          data: updatedItem,
        });
      }
    } else {
      const creationForm = {
        ...form,
        vulnerability:
          store.getters["vulnerability/getCurrentVulnerability"].uuid,
      };
      const newItem = await client.create(creationForm, sspMetadata());
      if (newItem) {
        await modelClass.insert({
          data: newItem,
        });
      }
    }
  },

  async deleteVulnerabilityRelatedModel(_, { uuid, model }) {
    const client = clients[model];
    const modelClass = models[model];

    await client.destroy(uuid, sspMetadata());
    return await modelClass.delete(uuid);
  },
};
export const mutations = {
  SET_CURRENT_VULNERABILITY: (state, vulnerability) => {
    state.currentVulnerability = vulnerability;
  },
};
export const getters = {
  getCurrentVulnerability: () => {
    return state.currentVulnerability;
  },
};
export const state = {
  currentVulnerability: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
