import { Agency, AgencyGroup } from "@socotec.io/socio-vue-components";
import User from "@/models/User";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { renameKeys } from "@/utils/utilsGrcpRest";
import { buildMetadata } from "@/utils/metadata";

const state = {};

const getters = {
  getCurrentUser: () => {
    return User.query()
      .where("isCurrentUser", true)
      .with("agencyDatas.agencyGroupDatas")
      .first();
  },

  isCurrentUserADeveloper: (state, getter) => {
    return getter?.getCurrentUser.isDev();
  },

  isCurrentUserTheProjectLead: (state, getter, rootState, rootGetters) => {
    return (
      rootGetters["contributors/getMissionProjectLead"]?.usermanagementUuid ===
      getter?.getCurrentUser?.usermanagementUuid
    );
  },

  isCurrentUserThePilot: (state, getter, rootState, rootGetters) => {
    return (
      rootGetters["contributors/getMissionPilot"]?.usermanagementUuid ===
      getter?.getCurrentUser?.usermanagementUuid
    );
  },

  isCurrentUserTheProjectSupervisor: (
    state,
    getter,
    rootState,
    rootGetters
  ) => {
    return (
      rootGetters[
        "contributors/getMissionSupervisor"
      ]?.userEmail.toLowerCase() === getter?.getCurrentUser?.email.toLowerCase()
    );
  },

  /**
   * Get user by email
   * @param email
   * @returns {User|null}
   */
  getUserByEmail: () => (email) => {
    return User.query().where("email", email).first();
  },
  getAgencyByUuid: () => (uuid) => {
    return Agency.query().where("uuid", uuid).first();
  },

  getAllAgencies: () => {
    return Agency.query().get();
  },
};

const actions = {
  async initializeCurrentUser({ dispatch }) {
    // Fetch user, his agency and agency group
    const userResult = await dispatch("fetchCurrentUser");
    const user = userResult.users[0];
    const agencyResult = await dispatch("fetchUserAgencyAndAgencyGroup", {
      uuid: user.usermanagementUuid,
    });

    if (!agencyResult) {
      return;
    }
    const agency = agencyResult.agency[0];

    await User.update({
      where: (user) => {
        return user.isCurrentUser;
      },
      data: {
        agency: agency.uuid,
      },
    });
  },
  async fetchUserAgencyAndAgencyGroup(context, { uuid }) {
    const request =
      new socioGrpcClient.user_management.entities.AgencyRetrieveUserAgencyRequest();
    request.setUuid(uuid);
    try {
      const response =
        await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.retrieveUserAgency(
          request,
          {}
        );
      const agency = response.toObject();
      if (!agency.uuid) {
        return;
      }

      if (agency.agencyGroup) {
        await AgencyGroup.insert({
          data: agency.agencyGroup,
        });
        // Only store agencyGroup relation
        agency.agencyGroup = agency.agencyGroup.uuid;
      }
      return await Agency.insert({
        data: agency,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async fetchCurrentUser({ rootGetters }) {
    const request = new socioGrpcClient.ssp_back.user.UserRetrieveRequest();
    request.setUuid(rootGetters["oidc/oidcUser"].usermanagementUuid);
    try {
      const response =
        await socioGrpcClient.ssp_back.user.UserControllerPromiseClient.retrieveByUsermanagementUuid(
          request,
          {}
        );
      const keysMap = {
        groupsList: "groups",
      };
      const userData = renameKeys(keysMap, response.toObject());
      return await User.insert({
        data: { ...userData, isCurrentUser: true },
      });
    } catch (err) {
      console.error(err);
    }
  },
  /**
   * Get user by mail
   * @param [context]
   * @param email {string}
   * @returns {Promise<User|null>}
   */
  async retrieveUserUsermanagementUuid(context, uuid) {
    const request =
      new socioGrpcClient.user_management.user.UserRetrieveRequest();

    request.setUuid(uuid);

    const response =
      await socioGrpcClient.user_management.user.UserControllerPromiseClient.retrieve(
        request,
        {}
      );

    const user = response.toObject();

    await User.insert({
      data: { user },
    });

    return user;
  },
  /**
   * Fetch agency by code
   * @param _
   * @param code {string} Agency code
   * @returns {Promise<Agency>}
   */
  async fetchAgencyByCode(_, code) {
    const request =
      new socioGrpcClient.user_management.entities.AgencyRetrieveAgencyByCodeRequest();

    request.setCode(code);

    const response =
      await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.retrieveAgencyByCode(
        request,
        {}
      );

    const agency = response.toObject();

    if (agency.uuid === "") {
      throw new Error("There is no agency with this code");
    }

    await Agency.insertOrUpdate({
      data: agency,
    });

    return agency;
  },
  async fetchAgencyByUuid(_, uuid) {
    const request =
      new socioGrpcClient.user_management.entities.AgencyRetrieveRequest();

    request.setUuid(uuid);

    const response =
      await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.retrieve(
        request,
        {}
      );
    const agency = response.toObject();

    if (agency.uuid === "") {
      throw new Error("There is no agency with this code");
    }

    await Agency.insertOrUpdate({
      data: agency,
    });

    return agency;
  },
  async searchUser(context, metaData) {
    const request = new socioGrpcClient.user_management.user.UserListRequest();
    const response =
      await socioGrpcClient.user_management.user.UserControllerPromiseClient.list(
        request,
        buildMetadata(metaData)
      );
    const users = response.toObject().resultsList;
    return users.map((user) => new User(user));
  },

  async fetchAgencies(context, metaData = {}) {
    metaData = {
      ...metaData,
      filters: {
        ...metaData.filters,
        application_name: process.env.VUE_APP_SSP_SERVICE_ID,
      },
    };
    const request =
      new socioGrpcClient.user_management.entities.AgencyListRequest();

    const response =
      await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.utils.listAllObjects(
        request,
        buildMetadata(metaData)
      );

    await Agency.insert({
      data: response,
    });

    return { resultsList: response.map((agency) => new Agency(agency)) };
  },

  async updateUserProfile(context, { uuid, signature }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.user.UserProfileRequest,
      { uuid, signature }
    );
    const response =
      await socioGrpcClient.ssp_back.user.UserControllerPromiseClient.updateProfile(
        request,
        {}
      );
    return await User.update({
      data: { ...response.toObject() },
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
