import NeighboringUse from "@/models/NeighboringUse";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import NeighboringUseElement from "@/models/NeighboringUseElement";

import store from "@/store";

const state = {
  neighboringUsesCount: 0,
  currentNeighbourUse: {},
};

const mutations = {
  SET_NEIGHBORING_USES_COUNT: (state, count) => {
    state.neighboringUsesCount = count;
  },
  SET_CURRENT_NEIGHBORING_USE: (state, neighboringUse) => {
    state.currentNeighbourUse = neighboringUse;
  },
};

const getters = {
  neiboringUsesList: (state, getters, rootState) => {
    return NeighboringUse.query()
      .where("siteUuid", rootState.site.currentSite?.uuid)
      .get();
  },
  getNeighboringUsesCount: (state) => {
    return state.neighboringUsesCount;
  },
};

const actions = {
  async fetchNeighboringUse({ commit }, metadata = {}) {
    await NeighboringUse.deleteAll();
    const site = store.getters["site/currentSite"];
    const request = new socioGrpcClient.ssp_back.projects.NeighborUseRequest();
    metadata.filters = {
      site: site.uuid,
    };
    const response =
      await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.list(
        request,
        sspMetadata(metadata)
      );
    if (response) {
      const { resultsList, count } = response.toObject();
      commit("SET_NEIGHBORING_USES_COUNT", count);
      await NeighboringUse.create({
        data: resultsList,
      });
    }
  },
  async createOrUpdateCurrentNeighboringUse({ commit }, neighboringUse) {
    let response = null;
    const site = store.getters["site/currentSite"];
    neighboringUse.site = site.uuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.NeighborUseRequest,
      neighboringUse
    );
    if (!neighboringUse.uuid || neighboringUse.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      if (response) {
        const neighboringUse = response.toObject();
        commit("SET_CURRENT_NEIGHBORING_USE", neighboringUse);
        await NeighboringUse.insert({
          data: neighboringUse,
        });
        return neighboringUse;
      }
    }
    response =
      await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    if (response) {
      return await NeighboringUse.insert({
        data: response.toObject(),
      });
    }
  },
  async deleteCurrentNeighboringUse(_, uuid) {
    const request = new socioGrpcClient.ssp_back.projects.NeighborUseRequest();
    request.setUuid(uuid);
    await socioGrpcClient.ssp_back.projects.NeighborUseControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    return NeighboringUse.delete(uuid);
  },
  async fetchNeighboringUsesElements({ commit }, neighboringUse) {
    commit("SET_CURRENT_NEIGHBORING_USE", neighboringUse);
    NeighboringUseElement.deleteAll();
    const metadata = {
      filters: {
        neighbour_use: neighboringUse.uuid,
      },
    };
    const request =
      new socioGrpcClient.ssp_back.projects.NeighbourUseElementRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.NeighbourUseElementControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { resultsList } = response.toObject();
    NeighboringUseElement.insert({
      data: resultsList,
    });
  },
  async createOrUpdateNeighboringUseElement(_, form) {
    const action = form.uuid ? "update" : "create";
    form.neighbourUse = state.currentNeighbourUse.uuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.NeighbourUseElementRequest,
      form
    );
    const response =
      await socioGrpcClient.ssp_back.projects.NeighbourUseElementControllerPromiseClient[
        action
      ](request, sspMetadata());
    const result = response.toObject();
    NeighboringUseElement.insertOrUpdate({
      data: result,
    });
  },
  async deleteNeighboringUseElement(_, uuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.NeighbourUseElementDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.projects.NeighbourUseElementControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await NeighboringUseElement.delete(uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
