import store from "@/store";
import {
  accidentClient,
  activityClient,
  pollutionClient,
  productClient,
  restrictionClient,
  ICPEClient,
  testimonyClient,
} from "@/store/api/testimonial";
import _ from "lodash";
import {
  zoneClient,
  componentClient,
  buildingClient,
  outdoorSpaceClient,
} from "@/store/api/cartography";
import Testimony from "@/models/testimonials/Testimony";
import Accident from "@/models/testimonials/Accident";
import Activity from "@/models/testimonials/Activity";
import Product from "@/models/testimonials/Product";
import Pollution from "@/models/testimonials/Pollution";
import Restriction from "@/models/testimonials/Restriction";
import ICPE from "@/models/testimonials/ICPE";
import { filterExcludedFields } from "@/utils/arrayUtils";
import { sspMetadata } from "@/utils/metadata";

const excludeFields = ["createdAt", "updatedAt"];

const clients = {
  ACCIDENTS: accidentClient,
  ACTIVITIES: activityClient,
  KNOWN_POLLUTION_SITES: pollutionClient,
  PRODUCTS: productClient,
  USAGE_RESTRICTIONS: restrictionClient,
  ICPE_CLASSIFICATIONS: ICPEClient,
};

const models = {
  ACCIDENTS: Accident,
  ACTIVITIES: Activity,
  KNOWN_POLLUTION_SITES: Pollution,
  PRODUCTS: Product,
  USAGE_RESTRICTIONS: Restriction,
  ICPE_CLASSIFICATIONS: ICPE,
};

export const actions = {
  async fetchItems(_, { type, metadata }) {
    const client = clients[type];
    const model = models[type];
    const filters = metadata.filters || {};
    const pagination = metadata.pagination || {};
    const { resultsList: items, count } = await client.list(
      filters,
      pagination
    );

    if (!items.length) {
      console.log(`No ${type.toLowerCase()} found`);
      return null;
    }

    await model.create({
      data: items,
    });
    return {
      items,
      count,
    };
  },

  async createOrUpdateHistoricalItem(_, { type, item }) {
    const client = clients[type];
    const model = models[type];
    const filteredItem = filterExcludedFields(item, excludeFields);

    if (!filteredItem.uuid || filteredItem.uuid.includes("$")) {
      const newItem = await client.create(filteredItem, sspMetadata());
      if (newItem) {
        await model.create({
          data: newItem,
        });
      }
    } else {
      const updatedItem = await client.partialUpdate(
        filteredItem,
        sspMetadata()
      );
      if (updatedItem) {
        await model.update({
          where: updatedItem.uuid,
          data: updatedItem,
        });
      }
    }
  },

  async deleteItem(_, { type, uuid }) {
    const client = clients[type];
    const model = models[type];

    await client.destroy(uuid, sspMetadata());
    return await model.delete(uuid);
  },
  async fetchTestimonies() {
    const { resultsList: testimonies } = await testimonyClient.list({
      project: store.getters["missions/currentMissionUuid"],
    });

    if (!testimonies.length) {
      console.log("No testimony found");
      return null;
    }
    await Testimony.create({
      data: testimonies,
    });
    return testimonies;
  },
  async createOrUpdateCurrentTestimony({ commit }, testimony) {
    testimony.project = store.getters["missions/currentMissionUuid"];
    let newTestimony = null;
    const filteredTestimony = filterExcludedFields(testimony, excludeFields);

    if (!filteredTestimony.uuid || filteredTestimony.uuid.includes("$")) {
      newTestimony = await testimonyClient.create(
        filteredTestimony,
        sspMetadata()
      );
      if (newTestimony) {
        await Testimony.create({
          data: newTestimony,
        });
      }
    } else {
      newTestimony = await testimonyClient.partialUpdate(
        filteredTestimony,
        sspMetadata()
      );
      if (newTestimony) {
        await Testimony.update({
          where: newTestimony.uuid,
          data: newTestimony,
        });
      }
    }
    commit("SET_STORED_TESTIMONY", newTestimony);
    return newTestimony;
  },
  async deleteCurrentTestimony(_, uuid) {
    await testimonyClient.destroy(uuid, sspMetadata());
    return await Testimony.delete(uuid);
  },

  async fetchTestimonyRemarkableElements(__, metadata) {
    const filters = JSON.parse(metadata.filters || "{}");
    const pagination = JSON.parse(metadata.pagination || "{}");
    const pageSize = pagination.page_size || 10;
    const page = pagination.page || 1;

    const markers = await Promise.all([
      zoneClient.list(filters),
      componentClient.list(filters),
      buildingClient.list(filters),
      outdoorSpaceClient.list(filters),
    ]);
    const resultsList = _.flatten(markers.map((el) => el.resultsList));
    const totalItems = resultsList.length;
    // Apply pagination manually
    const paginatedResults = resultsList.slice(
      (page - 1) * pageSize,
      page * pageSize
    );

    if (!paginatedResults.length) {
      console.log("No remarkable element found");
      return null;
    }
    return {
      items: paginatedResults,
      totalItems: totalItems,
    };
  },
  setStoredTestimony({ commit }, testimony) {
    commit("SET_STORED_TESTIMONY", testimony);
  },
};

export const mutations = {
  SET_STORED_TESTIMONY(state, testimony) {
    state.storedTestimony = testimony;
  },
};
export const getters = {};
export const state = {
  storedTestimony: new Testimony(),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
