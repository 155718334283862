import socioGrpcClient from "@/setup/socioGrpcClient";
import { Company } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {};

const actions = {
  async createCompany(context, company) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.contributors.contributors.CompanyRequest,
      company
    );
    const response =
      await socioGrpcClient.contributors.contributors.CompanyControllerPromiseClient.create(
        request,
        {}
      );

    const newCompany = new Company(response.toObject());
    await newCompany.$save();
    return newCompany;
  },

  async searchCompany(context, searchParam) {
    // Get only 20 first results
    const metadata = {
      filters: JSON.stringify({ search: searchParam }),
      pagination: JSON.stringify({
        page_size: 20,
        page: 1,
      }),
    };
    const request =
      new socioGrpcClient.contributors.contributors.CompanyListRequest();
    const response =
      await socioGrpcClient.contributors.contributors.CompanyControllerPromiseClient.list(
        request,
        metadata
      );
    return response
      .toObject()
      .resultsList.map((contributor) => new Company(contributor));
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
