import Site from "@/models/Site";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";

const state = {};

const mutations = {};

const getters = {
  currentSite: (state, getters, rootState) => {
    return (
      Site.query()
        .where("project", rootState.missions.currentMissionUuid)
        .first() || new Site()
    );
  },
};

const actions = {
  async fetchCurrentSiteWithImmediateDangers() {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    try {
      const request =
        new socioGrpcClient.ssp_back.projects.SiteGetSiteWithImmediateDangersRequest();
      request.setProjectId(projectUuid);
      const response =
        await socioGrpcClient.ssp_back.projects.SiteControllerPromiseClient.getSiteWithImmediateDangers(
          request,
          sspMetadata()
        );
      if (response) {
        await Site.create({
          data: response.toObject(),
        });
        return response.toObject();
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async fetchCurrentSiteWithCartographyElements() {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    try {
      const request =
        new socioGrpcClient.ssp_back.projects.SiteGetSiteWithCartographyElementsRequest();
      request.setProjectId(projectUuid);
      const response =
        await socioGrpcClient.ssp_back.projects.SiteControllerPromiseClient.getSiteWithCartographyElements(
          request,
          sspMetadata()
        );
      if (response) {
        await Site.create({
          data: response.toObject(),
        });
        return response.toObject();
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async fetchCurrentSiteWithDocumentData() {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    const request =
      new socioGrpcClient.ssp_back.projects.SiteGetSiteWithNeighboringUsesLocationPlanRequest();
    request.setProjectId(projectUuid);
    const response =
      await socioGrpcClient.ssp_back.projects.SiteControllerPromiseClient.getSiteWithNeighboringUsesLocationPlan(
        request,
        sspMetadata()
      );

    if (response) {
      await Site.create({
        data: response.toObject(),
      });
      return response.toObject();
    }
  },
  async createOrUpdateCurrentSite(_, site) {
    let response = null;
    const mission = await store.getters["missions/currentMission"];
    site.project = mission.uuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.SiteRequest,
      site
    );
    if (!site.uuid || site.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.SiteControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      return await Site.insert({
        data: response.toObject(),
      });
    }
    response =
      await socioGrpcClient.ssp_back.projects.SiteControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    return await Site.update({
      where: site.uuid,
      data: response.toObject(),
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
