import UnderGroundWaterInformation from "@/models/investegatedEnvs/UnderGroundWaterInformation";

import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import store from "@/store";

const FIELDS_TO_EXCLUDE = ["$id"];

const state = {};

const mutations = {};
const getters = {
  currentUnderGroundWaterInformation: (state, getters, rootState) => {
    const underGroundWaterInformation = new UnderGroundWaterInformation();
    underGroundWaterInformation.setProject(
      rootState.missions.currentMissionUuid
    );
    const result =
      UnderGroundWaterInformation.query()
        .where("project", rootState.missions.currentMissionUuid)
        .first() || underGroundWaterInformation;
    return result;
  },
};
const actions = {
  async retrieveUnderGroundWaterInformationByMission() {
    try {
      const projectUuid = store.getters["missions/currentMissionUuid"];
      const request =
        new socioGrpcClient.ssp_back.intervention.UnderGroundWaterInformationRetrieveByProjectRequest();
      request.setProjectId(projectUuid);
      const response =
        await socioGrpcClient.ssp_back.intervention.UnderGroundWaterInformationControllerPromiseClient.retrieveByProject(
          request,
          sspMetadata()
        );
      await UnderGroundWaterInformation.create({
        data: response.toObject(),
      });
      return response.toObject();
    } catch (error) {
      console.log("error", error);
    }
  },

  async createOrUpdateUnderGroundWaterInformation(
    _,
    underGroundWaterInformation
  ) {
    let response = null;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.UnderGroundWaterInformationRequest,
      underGroundWaterInformation,
      FIELDS_TO_EXCLUDE
    );
    if (
      !underGroundWaterInformation.uuid ||
      underGroundWaterInformation.uuid.includes("$")
    ) {
      response =
        await socioGrpcClient.ssp_back.intervention.UnderGroundWaterInformationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      await UnderGroundWaterInformation.insert({
        data: response.toObject(),
      });
      return response.toObject();
    }

    response =
      await socioGrpcClient.ssp_back.intervention.UnderGroundWaterInformationControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    await UnderGroundWaterInformation.update({
      where: underGroundWaterInformation.uuid,
      data: response.toObject(),
    });
    return response.toObject();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
