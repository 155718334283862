import ProjectInformation from "@/models/ProjectInformation";
import Attachment from "@/models/Attachments";
import AdditionalPLans from "@/models/AdditionalPlans";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import store from "@/store";

const state = {};

const getters = {
  currentProjectInformation: () => {
    return ProjectInformation.query().first() || {};
  },
};

const actions = {
  async fetchOrCreateCurrentProjectInformation({ dispatch }) {
    try {
      const mission = await store.getters["missions/currentMission"];
      const metadata = {
        filters: {
          project: mission.uuid,
        },
      };
      const request =
        new socioGrpcClient.ssp_back.projects.ProjectInformationListRequest();
      const response =
        await socioGrpcClient.ssp_back.projects.ProjectInformationControllerPromiseClient.list(
          request,
          buildMetadata(metadata)
        );
      if (response) {
        await ProjectInformation.create({
          data: response.toObject().resultsList[0],
        });
      }
    } catch (error) {
      console.error("Error fetching project information", error);
      const newProjectInfo = await dispatch(
        "createOrUpdateCurrentProjectInformation",
        {}
      );
      return newProjectInfo;
    }
  },

  async createOrUpdateCurrentProjectInformation(_, projectInformation) {
    const mission = await store.getters["missions/currentMission"];
    projectInformation.project = mission.uuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.ProjectInformationRequest,
      projectInformation
    );
    let response;
    if (!projectInformation.uuid || projectInformation.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.ProjectInformationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      return await ProjectInformation.create({
        data: response.toObject(),
      });
    } else {
      response =
        await socioGrpcClient.ssp_back.projects.ProjectInformationControllerPromiseClient.update(
          request,
          sspMetadata()
        );
      return await ProjectInformation.update({
        where: projectInformation.uuid,
        data: response.toObject(),
      });
    }
  },

  async createProjectInformationAttachment(_, attachment) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.AttachmentRequest,
      attachment
    );
    const response =
      await socioGrpcClient.ssp_back.projects.AttachmentControllerPromiseClient.create(
        request,
        sspMetadata()
      );
    return await Attachment.insert({
      data: response.toObject(),
    });
  },
  async deleteProjectInformationAttachment(_, uuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.AttachmentDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.projects.AttachmentControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    await Attachment.delete(uuid);
  },

  async fetchProjectInformationAttachments(_, metadata) {
    const request =
      new socioGrpcClient.ssp_back.projects.AttachmentListRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.AttachmentControllerPromiseClient.list(
        request,
        sspMetadata(metadata)
      );
    await Attachment.insert({
      data: response.toObject(),
    });
    const resultsList = response.toObject().resultsList;
    return resultsList;
  },

  async createProjectInformationAdditionalPlan(_, additionalPlan) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.AdditionalPlansRequest,
      additionalPlan
    );
    const response =
      await socioGrpcClient.ssp_back.projects.AdditionalPlanControllerPromiseClient.create(
        request,
        sspMetadata()
      );
    return await AdditionalPLans.insert({
      data: response.toObject(),
    });
  },

  async deleteProjectInformationAdditionalPlan(_, uuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.AdditionalPlansDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.projects.AdditionalPlanControllerPromiseClient.destroy(
      request,
      {}
    );
    await AdditionalPLans.delete(uuid);
  },

  async fetchProjectInformationAdditionalPlans(_, metadata) {
    const request =
      new socioGrpcClient.ssp_back.projects.AdditionalPlansListRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.AdditionalPlanControllerPromiseClient.list(
        request,
        sspMetadata(metadata)
      );
    await AdditionalPLans.insert({
      data: response.toObject(),
    });
    const resultsList = response.toObject().resultsList;
    return resultsList;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
