import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";
import ComplementaryAnalysis from "@/models/ComplementaryAnalysis";
import store from "@/store";
import {
  buildProtoMessageFromList,
  renameKeys,
  responseTojavascript,
} from "@/utils/utilsGrcpRest";

const COMPLEMENTARY_ANALYSIS_FIELDS_TO_EXCLUDE = [
  "$id",
  "createdAt",
  "updatedAt",
  "analysisData",
  "sentStatus",
];

const COMPLEMENTARY_ANALYSIS_KEYS_MAP = {
  analysisList: "analysis",
};

const state = {};

const mutations = {};

const getters = {
  getComplementaryAnalysisPerSamples: () => (samplesUuid) => {
    return ComplementaryAnalysis.query()
      .where("sample", (sample) => samplesUuid.includes(sample))
      .with("analysisData")
      .get();
  },
};

const actions = {
  async fetchComplementaryAnalysis() {
    try {
      const request =
        new socioGrpcClient.ssp_back.intervention.ComplementaryAnalysisListComplementaryAnalysisByProjectRequest();
      const projectUuid = store.getters["missions/currentMissionUuid"];
      request.setProjectId(projectUuid);
      const response =
        await socioGrpcClient.ssp_back.intervention.ComplementaryAnalysisControllerPromiseClient.listComplementaryAnalysisByProject(
          request,
          sspMetadata()
        );
      const { resultsList } = response.toObject();
      if (resultsList && Array.isArray(resultsList)) {
        await ComplementaryAnalysis.insert({
          data: resultsList.map((complementaryAnalysis) =>
            renameKeys(COMPLEMENTARY_ANALYSIS_KEYS_MAP, complementaryAnalysis)
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  async bulkCreateComplementaryAnalysis(_, complementaryAnalysis) {
    const { intervention } = socioGrpcClient.ssp_back;
    const complementaryAnalysisAsProtoMessage = buildProtoMessageFromList(
      complementaryAnalysis,
      socioGrpcClient.javascriptToRequest,
      intervention.ComplementaryAnalysisRequest,
      COMPLEMENTARY_ANALYSIS_FIELDS_TO_EXCLUDE
    );
    const request = socioGrpcClient.javascriptToRequest(
      intervention.ComplementaryAnalysisBulkCreateListRequest,
      { complementaryAnalysis: complementaryAnalysisAsProtoMessage }
    );
    const response =
      await intervention.ComplementaryAnalysisControllerPromiseClient.bulkCreateComplementaryAnalysis(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(
      response,
      COMPLEMENTARY_ANALYSIS_KEYS_MAP,
      "complementaryAnalysisList"
    );
    await ComplementaryAnalysis.insert({ data });

    return data;
  },

  async bulkUpdateComplementaryAnalysis(_, complementaryAnalysis) {
    const { intervention } = socioGrpcClient.ssp_back;
    const complementaryAnalysisAsProtoMessage = buildProtoMessageFromList(
      complementaryAnalysis,
      socioGrpcClient.javascriptToRequest,
      intervention.ComplementaryAnalysisUpdateRequest,
      COMPLEMENTARY_ANALYSIS_FIELDS_TO_EXCLUDE
    );
    const request = socioGrpcClient.javascriptToRequest(
      intervention.ComplementaryAnalysisUpdateListRequest,
      { complementaryAnalysis: complementaryAnalysisAsProtoMessage }
    );
    const response =
      await intervention.ComplementaryAnalysisControllerPromiseClient.bulkUpdateComplementaryAnalysis(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(
      response,
      COMPLEMENTARY_ANALYSIS_KEYS_MAP,
      "complementaryAnalysisList"
    );
    await ComplementaryAnalysis.update({
      data: data,
    });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
