import { isValid } from "date-fns";

export const arrayEqual = (array1, array2) =>
  array1.length === array2.length && array1.every((v, i) => v === array2[i]);

export const getDatesFromObject = (object) => {
  return Object.entries(object).reduce((arr, [, value]) => {
    if (typeof value !== "boolean" && isValid(new Date(value))) {
      arr.push(value);
    }
    return arr;
  }, []);
};

export const removeItemFromList = (item, list) => {
  list.splice(list.indexOf(item), 1);
};

export const filterExcludedFields = (obj, excludeFields) => {
  return Object.keys(obj)
    .filter((key) => !excludeFields.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};
