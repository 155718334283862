import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";

const state = {
  currentMissionUuid: null,
};

const getters = {};

const actions = {
  async createTechnicalOffer(_, technicalOffer) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.TechnicalOfferRequest,
      technicalOffer
    );
    const response =
      await socioGrpcClient.ssp_back.projects.ProjectControllerPromiseClient.createTechnicalOffer(
        request,
        sspMetadata()
      );
    return response.toObject();
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
};
