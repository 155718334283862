const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");

import Investigation from "@/models/Report/Investigation";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";
import SoilAnalysisProgram from "@/models/Report/SoilAnalysisProgram.js";
import SoilGasInvestigation from "@/models/Report/SoilGasInvestigation";
import Weather from "@/models/Report/Weather";
import SoilGasLaboratoryAnalysis from "@/models/Report/SoilGasLaboratoryAnalysis";
import SamplingTimeAndFlowRate from "@/models/Report/SamplingTimeAndFlowRate";
import SampleEnvironment from "@/models/Report/SampleEnvironment.js";
import FoodLaboratoryAnalysis from "@/models/Report/FoodLaboratoryAnalysis.js";
import UndergroundWaterAnalyticalProgram from "@/models/Report/UndergroundWaterAnalyticalProgram.js";
import UndergroundWaterInvestigationsStructure from "@/models/Report/UndergroundWaterInvestigationsStructure.js";
import ExcavatedGroundAnalysisProgram from "@/models/Report/ExcavatedGroundAnalysisProgram";
import AmbientAirAnalysisProgram from "@/models/Report/AmbientAirAnalysisProgram";
import AmbientAirSamplingJustification from "@/models/Report/AmbientAirSamplingJustification";
import GeologicalWorkNearSite from "@/models/Report/GeologicalWorkNearSite";
import IdentifiedGroundwaterCatchment from "@/models/Report/IdentifiedGroundwaterCatchment";
import MonitoringStructureNearSite from "@/models/Report/MonitoringStructureNearSite";
import PiezometricLevel from "@/models/Report/PiezometricLevel";
import WaterPhysicoChemicalComposition from "@/models/Report/WaterPhysicoChemicalComposition";
import relatedToReportQuestionnaireModuleFactory from "@/composables/questionnaire/tree-form/report/useRelatedToReportQuestionnaireStoreModule.js";
import PhotographsPlansAnalysis from "@/models/Report/PhotographsPlansAnalysis";
import ActivitiesAndProcessesHistory from "@/models/Report/ActivitiesAndProcessesHistory";
import AdministrativeSituationsHistory from "@/models/Report/AdministrativeSituationsHistory";
import UsedProductAndWaste from "@/models/Report/UsedProductAndWaste";
import ProductUsedAndRawMaterial from "@/models/Report/ProductUsedAndRawMaterial";
import RiskyActivity from "@/models/Report/RiskyActivity";
import SiteSourceOfContamination from "@/models/Report/SiteSourceOfContamination";
import SuperficialWaterLaboratoryAnalysis from "@/models/Report/SuperficialWaterLaboratoryAnalysis";
import BasiasBasolSite from "@/models/Report/BasiasBasolSite";

const REPORT_RELATED_FIELDS_TO_EXCLUDE = ["$id"];
const basiasBasolSite =
  relatedToReportQuestionnaireModuleFactory(BasiasBasolSite);
const photographsPlansAnalysis = relatedToReportQuestionnaireModuleFactory(
  PhotographsPlansAnalysis
);
const superficialWaterLaboratoryAnalysis =
  relatedToReportQuestionnaireModuleFactory(SuperficialWaterLaboratoryAnalysis);
const activitiesAndProcessesHistory = relatedToReportQuestionnaireModuleFactory(
  ActivitiesAndProcessesHistory
);
const administrativeSituationsHistory =
  relatedToReportQuestionnaireModuleFactory(AdministrativeSituationsHistory);
const usedProductAndWaste =
  relatedToReportQuestionnaireModuleFactory(UsedProductAndWaste);
const productUsedAndRawMaterial = relatedToReportQuestionnaireModuleFactory(
  ProductUsedAndRawMaterial
);
const riskyActivity = relatedToReportQuestionnaireModuleFactory(RiskyActivity);
const siteSourceOfContamination = relatedToReportQuestionnaireModuleFactory(
  SiteSourceOfContamination
);
const sampleEnvironment =
  relatedToReportQuestionnaireModuleFactory(SampleEnvironment);
const foodLaboratoryAnalysis = relatedToReportQuestionnaireModuleFactory(
  FoodLaboratoryAnalysis
);
const soilAnalysisProgram =
  relatedToReportQuestionnaireModuleFactory(SoilAnalysisProgram);
const undergroundWaterAnalyticalProgram =
  relatedToReportQuestionnaireModuleFactory(UndergroundWaterAnalyticalProgram);
const undergroundWaterInvestigationsStructure =
  relatedToReportQuestionnaireModuleFactory(
    UndergroundWaterInvestigationsStructure
  );
const soilGasInvestigation =
  relatedToReportQuestionnaireModuleFactory(SoilGasInvestigation);
const soilGasLaboratoryAnalysis = relatedToReportQuestionnaireModuleFactory(
  SoilGasLaboratoryAnalysis
);
const samplingTimeAndFlowRate = relatedToReportQuestionnaireModuleFactory(
  SamplingTimeAndFlowRate
);
const weather = relatedToReportQuestionnaireModuleFactory(Weather);
const excavatedGroundAnalysisProgram =
  relatedToReportQuestionnaireModuleFactory(ExcavatedGroundAnalysisProgram);
const ambientAirAnalysisProgram = relatedToReportQuestionnaireModuleFactory(
  AmbientAirAnalysisProgram
);
const ambientAirSamplingJustification =
  relatedToReportQuestionnaireModuleFactory(AmbientAirSamplingJustification);
const piezometricLevel =
  relatedToReportQuestionnaireModuleFactory(PiezometricLevel);
const waterPhysicoChemicalComposition =
  relatedToReportQuestionnaireModuleFactory(WaterPhysicoChemicalComposition);
const geologicalWorkNearSite = relatedToReportQuestionnaireModuleFactory(
  GeologicalWorkNearSite
);
const identifiedGroundwaterCatchment =
  relatedToReportQuestionnaireModuleFactory(IdentifiedGroundwaterCatchment);
const monitoringStructuresNearSite = relatedToReportQuestionnaireModuleFactory(
  MonitoringStructureNearSite
);
const getters = {
  ...soilAnalysisProgram.getters,
  ...sampleEnvironment.getters,
  ...foodLaboratoryAnalysis.getters,
  ...undergroundWaterAnalyticalProgram.getters,
  ...undergroundWaterInvestigationsStructure.getters,
  ...soilGasInvestigation.getters,
  ...soilGasLaboratoryAnalysis.getters,
  ...samplingTimeAndFlowRate.getters,
  ...weather.getters,
  ...excavatedGroundAnalysisProgram.getters,
  ...ambientAirAnalysisProgram.getters,
  ...ambientAirSamplingJustification.getters,
  ...piezometricLevel.getters,
  ...waterPhysicoChemicalComposition.getters,
  ...photographsPlansAnalysis.getters,
  ...activitiesAndProcessesHistory.getters,
  ...administrativeSituationsHistory.getters,
  ...usedProductAndWaste.getters,
  ...productUsedAndRawMaterial.getters,
  ...riskyActivity.getters,
  ...siteSourceOfContamination.getters,
  ...geologicalWorkNearSite.getters,
  ...identifiedGroundwaterCatchment.getters,
  ...monitoringStructuresNearSite.getters,
  ...superficialWaterLaboratoryAnalysis.getters,
  ...basiasBasolSite.getters,

  getInvestigations: (state, getters, rootState, rootGetters) => {
    const currentProvisionalProgram =
      rootGetters["reportChapters/getCurrentProvisionalProgram"];
    const investigations = Investigation.query()
      .where("provisionalProgram", currentProvisionalProgram.uuid)
      .get();
    return investigations;
  },
};

const actions = {
  ...soilAnalysisProgram.actions,
  ...sampleEnvironment.actions,
  ...foodLaboratoryAnalysis.actions,
  ...undergroundWaterAnalyticalProgram.actions,
  ...undergroundWaterInvestigationsStructure.actions,
  ...soilGasInvestigation.actions,
  ...soilGasLaboratoryAnalysis.actions,
  ...samplingTimeAndFlowRate.actions,
  ...weather.actions,
  ...excavatedGroundAnalysisProgram.actions,
  ...ambientAirAnalysisProgram.actions,
  ...ambientAirSamplingJustification.actions,
  ...piezometricLevel.actions,
  ...waterPhysicoChemicalComposition.actions,
  ...photographsPlansAnalysis.actions,
  ...activitiesAndProcessesHistory.actions,
  ...administrativeSituationsHistory.actions,
  ...usedProductAndWaste.actions,
  ...productUsedAndRawMaterial.actions,
  ...riskyActivity.actions,
  ...siteSourceOfContamination.actions,
  ...geologicalWorkNearSite.actions,
  ...identifiedGroundwaterCatchment.actions,
  ...monitoringStructuresNearSite.actions,
  ...superficialWaterLaboratoryAnalysis.actions,
  ...basiasBasolSite.actions,

  async fetchInvestigations({ rootGetters }) {
    const request =
      new socioGrpcClient.ssp_back.report.InvestigationListRequest();
    const metadata = {
      ...buildMetadata({
        filters: {
          provisionalProgram:
            rootGetters["reportChapters/getCurrentProvisionalProgram"].uuid,
        },
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.report.InvestigationControllerPromiseClient.list(
        request,
        metadata
      );
    let responseObject = response.getResultsList();
    /*
       We have to iterate over all investigation to use the function toJavaScript()
       on the JSON FIELD to have a friendly javascript output
       in other case the grpc give something like that:
        "reportGenerationData": {
          "fieldsMap": [
            [ "test", { "nullValue": 0, "numberValue": 0, "stringValue": "test", "boolValue": false } ],
            [ "title", { "nullValue": 0, "numberValue": 0, "stringValue": "Titre", "boolValue": false } ]
          ]
        }
        That's why we don't use directly response.toObject().resultList
      */
    const investigations = responseObject.map((investigation) => {
      const investigationObj = investigation.toObject();
      /* Storing compound as entries in front to handle them easier */
      investigationObj.compoundToSeek = investigation
        .getCompoundToSeek()
        .toJavaScript();
      return investigationObj;
    });

    return await Investigation.create({
      data: investigations,
    });
  },

  async createOrUpdateInvestigation(_, investigationData) {
    investigationData.compoundToSeek =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        // Necessary because the fromJavascript method only supports the object type
        // and not class instances (Like vuex ORM models)
        JSON.parse(JSON.stringify(investigationData.compoundToSeek))
      );
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.report.InvestigationRequest,
      investigationData,
      REPORT_RELATED_FIELDS_TO_EXCLUDE
    );

    let response = null;
    if (investigationData.uuid) {
      response =
        await socioGrpcClient.ssp_back.report.InvestigationControllerPromiseClient.update(
          request,
          sspMetadata()
        );
    } else {
      response =
        await socioGrpcClient.ssp_back.report.InvestigationControllerPromiseClient.create(
          request,
          sspMetadata()
        );
    }

    const newInvestigation = response.toObject();
    newInvestigation.compoundToSeek = response
      .getCompoundToSeek()
      .toJavaScript();

    return await Investigation.insertOrUpdate({
      data: newInvestigation,
    });
  },
  async deleteInvestigation(_, investigationUuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.report.InvestigationDestroyRequest,
      { uuid: investigationUuid }
    );
    await socioGrpcClient.ssp_back.report.InvestigationControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
  },
};

export default {
  namespaced: true,
  getters,
  actions,
};
