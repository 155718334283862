import Sample from "@/models/Sample";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";
import {
  responseTojavascript,
  buildProtoMessageFromList,
} from "@/utils/utilsGrcpRest";
import ComplementaryAnalysisRequest from "@/models/ComplementaryAnalysisRequest";
const SAMPLE_FIELDS_TO_EXCLUDE = [
  "$id",
  "analysisData",
  "complementaryAnalysis",
  "pumpAverageFlow",
  "isSamplingLengthValid",
  "samplingDuration",
  "validFlowDifference",
  "retainedFlow",
  "volumeSampled",
];
const SAMPLE_KEYS_MAP = {
  barCodesList: "barCodes",
  analysisList: "analysis",
  lithologicalLayersList: "lithologicalLayers",
};

const state = {
  samplesCount: 0,
};

const mutations = {
  SET_SAMPLES_COUNT: (state, count) => {
    state.samplesCount = count;
  },
};

const getters = {
  samplesCount: (state) => {
    return state.samplesCount;
  },
};

const actions = {
  async fetchSamples(_, { metadata }) {
    const request =
      new socioGrpcClient.ssp_back.intervention.SampleListRequest();
    metadata.pagination = { page_size: 1000 };
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const { data } = responseTojavascript(response, SAMPLE_KEYS_MAP);
    await Sample.insert({
      data: data,
    });
  },

  async deleteSample({ commit, getters }, { uuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SampleDestroyRequest,
      { uuid }
    );
    await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    commit("SET_SAMPLES_COUNT", getters.samplesCount - 1);

    await Sample.delete(uuid);
  },

  async createSample({ commit, getters }, sample) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SampleRequest,
      sample,
      SAMPLE_FIELDS_TO_EXCLUDE
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, SAMPLE_KEYS_MAP);

    commit("SET_SAMPLES_COUNT", getters.samplesCount + 1);

    await Sample.insert({
      data: data,
    });
  },
  async updateSample(_, sample) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SampleRequest,
      sample,
      SAMPLE_FIELDS_TO_EXCLUDE
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, SAMPLE_KEYS_MAP);

    await Sample.update({
      where: sample.uuid,
      data: data,
    });
  },

  async sendForValidation(_, { samplesUuids, project }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SampleUuidsListRequest,
      { samplesUuids, project }
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.sendForValidation(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response);

    await Sample.update({
      data: data,
    });
  },

  async validate(_, { samplesUuids, project }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SampleUuidsListRequest,
      { samplesUuids, project }
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.validate(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response);

    await Sample.update({
      data: data,
    });
  },

  async sendAnalysisRequest(
    _,
    { samplesUuids, project, priorityLevel, trackingCode }
  ) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SampleUuidsListRequest,
      { samplesUuids, project, priorityLevel, trackingCode }
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.sendAnalysisRequest(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response);

    await Sample.update({
      data: data,
    });
  },

  async sendComplementaryAnalysisRequest(
    _,
    { samplesUuids, project, priorityLevel, eurofinsCaseNumber }
  ) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.SendComplementaryAnalysisRequest,
      { samplesUuids, project, priorityLevel, eurofinsCaseNumber }
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.SampleControllerPromiseClient.sendComplementaryAnalysis(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response);

    await ComplementaryAnalysisRequest.insert({
      data: data,
    });
  },

  async bulkSampleAnalysisUpdate(_, samples) {
    const { intervention } = socioGrpcClient.ssp_back;
    const samplesAsProtoMessage = buildProtoMessageFromList(
      samples,
      socioGrpcClient.javascriptToRequest,
      intervention.SampleAnalysisUpdateRequest,
      SAMPLE_FIELDS_TO_EXCLUDE
    );
    const request = socioGrpcClient.javascriptToRequest(
      intervention.SampleAnalysisUpdateListRequest,
      { samples: samplesAsProtoMessage }
    );
    const response =
      await intervention.SampleControllerPromiseClient.bulkSampleAnalysisUpdate(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(
      response,
      SAMPLE_KEYS_MAP,
      "samplesList"
    );
    await Sample.update({
      data: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
