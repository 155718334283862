import ReportQuestionnaire from "@/models/Report/Questionnaire";
import socioGrpcClient from "@/setup/socioGrpcClient";
import SspReport from "@/models/SspReport";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { removeObjectsFieldsEqualTo } from "@/utils/reportUtils";
import { renameKeys } from "@/utils/utilsGrcpRest";

const state = {};

const getters = {
  // Questionnaire
  getCurrentReportQuestionnaire: (state, getters, rootState, rootGetters) => {
    const currentMission = rootGetters["missions/currentMission"];
    const currentQuestionnaire = ReportQuestionnaire.query()
      .where("project", currentMission.uuid)
      .with("referenceDocuments");
    if (currentQuestionnaire.exists()) {
      return currentQuestionnaire.get()[0];
    }
    return new ReportQuestionnaire({ project: currentMission.uuid });
  },
};

const actions = {
  // Questionnaire
  async fetchCurrentReportQuestionnaire({ rootGetters }) {
    const request =
      new socioGrpcClient.ssp_back.report.QuestionnaireListRequest();
    const metadata = {
      filters: JSON.stringify({
        project: rootGetters["missions/currentMission"].uuid,
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.report.QuestionnaireControllerPromiseClient.list(
        request,
        metadata
      );
    let questionnaire = {};
    const responseList = response.toObject().resultsList;
    if (responseList.length >= 1) {
      questionnaire = responseList[0];
      if (responseList.length > 1) {
        console.error(`We have found ${responseList.length} Questionnaire, expected only one by Mission.
            Only '${questionnaire.uuid}' will be considered.`);
      }
    }
    const keysMap = {
      referenceDocumentsList: "referenceDocuments",
      samplingPointsPhotosList: "samplingPointsPhotos",
      siteVisitPhotosList: "siteVisitPhotos",
      technicalSummaryTransferPathList: "technicalSummaryTransferPath",
      interpretationAnalyzesDocsList: "interpretationAnalyzesDocs",
      additionalAnnexDocsList: "additionalAnnexDocs",
      exposureRoutesZonesList: "exposureRoutesZones",
      analysisResultsDocsList: "analysisResultsDocs",
    };
    questionnaire = renameKeys(keysMap, questionnaire);
    // Deleting empty strings to enable VueXORM to assign default values to empty string fields.
    questionnaire = removeObjectsFieldsEqualTo(questionnaire, "");
    return await ReportQuestionnaire.create({
      data: questionnaire,
    });
  },

  async updateCurrentReportQuestionnaire(context, questionnaire) {
    const excludeFields = ["$id"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.report.QuestionnaireRequest,
      questionnaire,
      excludeFields
    );

    let response = null;
    response =
      await socioGrpcClient.ssp_back.report.QuestionnaireControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const newQuestionnaire = response.toObject();
    return await ReportQuestionnaire.insert({
      data: newQuestionnaire,
    });
  },
  async fetchReportList(_, metadata) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const responseObject = response.getResultsList();

    const reportsList = responseObject.map((element) => element.toObject());
    return await SspReport.insert({
      data: reportsList,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
