import Parcel from "@/models/Parcel";

const getters = {
  getMissionParcels: (state, getters, rootState, rootGetters) => {
    const currentMission = rootGetters["missions/currentMission"];
    return Parcel.query().where("project", currentMission.uuid).get();
  },
};

export default {
  namespaced: true,
  getters,
};
