import cloneDeep from "lodash.clonedeep";

export const removeObjectsFieldsEqualTo = (object, fieldValue = "") => {
  let resultObject = cloneDeep(object);
  Object.keys(resultObject).forEach((key) => {
    if (object[key] === fieldValue) {
      delete resultObject[key];
    }
  });
  return resultObject;
};
