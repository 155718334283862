import { Client } from "@socotec.io/socio-vue-components";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { sspMetadata } from "@/utils/metadata";

const CLIENT_FIELDS_TO_EXCLUDE = ["createdAt", "updatedAt"];

const state = {};

const getters = {};

const actions = {
  async fetchClient(_, clientId) {
    const request =
      new socioGrpcClient.ssp_back.projects.ClientRetrieveRequest();

    request.setUuid(clientId);

    const response =
      await socioGrpcClient.ssp_back.projects.ClientControllerPromiseClient.retrieve(
        request,
        {}
      );
    const client = response.toObject();

    await Client.insert({
      data: client,
    });

    return client;
  },

  async updateClient(_, client) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.ClientRequest,
      client,
      CLIENT_FIELDS_TO_EXCLUDE
    );
    const response =
      await socioGrpcClient.ssp_back.projects.ClientControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    await Client.update({
      data: response.toObject(),
    });
  },

  /**
   * Retrieve a atlas client
   * @param [_]
   * @param clientId Client uuid
   * @returns {Promise<Client>}
   */
  async fetchAtlasClient(_, clientId) {
    const request =
      new socioGrpcClient.atlas_as_a_service.atlas.ClientRetrieveRequest();
    request.setUuid(clientId);
    const response =
      await socioGrpcClient.atlas_as_a_service.atlas.ClientControllerPromiseClient.retrieve(
        request,
        {}
      );

    return response.toObject();
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
