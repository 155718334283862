import socioGrpcClient from "@/setup/socioGrpcClient";
import ContactForm from "@/models/ContactForm/ContactForm";
import { sspMetadata } from "@/utils/metadata";
import {
  responseTojavascript,
  buildProtoMessageFromList,
} from "@/utils/utilsGrcpRest";
import SiteBuilding from "@/models/ContactForm/SiteBuilding";
import SiteActivity from "@/models/ContactForm/SiteActivity";

const CONTACT_FIELD_TO_EXCLUDE = ["$id", "id"];

const CONTACT_KEYS_MAP = {
  serviceObjectivesList: "serviceObjectives",
  requestContextsList: "requestContexts",
  equipementsAndStoragesList: "equipementsAndStorages",
  siteBuildingsList: "siteBuildings",
  siteActivitiesList: "siteActivities",
};

const state = {};

const mutations = {};

const getters = {
  currentContact: () => {
    return (
      ContactForm.query()
        .with("siteActivities")
        .with("siteBuildings")
        .first() || {}
    );
  },
};

const buildContactRelatedFieldListProto = (contact, fieldsToExclude = []) => {
  const siteBuildingsAsProto = buildProtoMessageFromList(
    contact.siteBuildings,
    socioGrpcClient.javascriptToRequest,
    socioGrpcClient.ssp_back.contact_form.SiteBuildingRequest,
    fieldsToExclude
  );

  const siteActivitiesAsProto = buildProtoMessageFromList(
    contact.siteActivities,
    socioGrpcClient.javascriptToRequest,
    socioGrpcClient.ssp_back.contact_form.SiteActivityRequest,
    fieldsToExclude
  );

  return {
    siteBuildingsAsProto,
    siteActivitiesAsProto,
  };
};

const insertContactData = (contact) => {
  const { siteBuildings = [], siteActivities = [] } = contact;

  if (contact.uuid) {
    ContactForm.create({
      data: {
        ...contact,
        id: contact.uuid,
      },
    });
  } else {
    ContactForm.create({
      data: {
        ...contact,
      },
    });
  }

  SiteBuilding.create({
    data: siteBuildings,
  });

  SiteActivity.create({
    data: siteActivities,
  });
};

const actions = {
  async fetchContact({ rootGetters }) {
    const metadata = {
      filters: {
        project: rootGetters["missions/currentMissionUuid"],
      },
    };
    const request =
      new socioGrpcClient.ssp_back.contact_form.ContactListRequest();

    const response =
      await socioGrpcClient.ssp_back.contact_form.ContactControllerPromiseClient.list(
        request,
        sspMetadata(metadata)
      );

    const { data } = responseTojavascript(response, CONTACT_KEYS_MAP);

    const contactToInsert = data[0] || new ContactForm();

    insertContactData(contactToInsert);
  },

  async createContact(_, contactData) {
    const FIELDS_TO_EXCLUDE = ["contact", "id", "$id"];

    const { siteBuildingsAsProto, siteActivitiesAsProto } =
      buildContactRelatedFieldListProto(contactData, FIELDS_TO_EXCLUDE);

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.contact_form.ContactRequest,
      {
        ...contactData,
        siteBuildings: siteBuildingsAsProto,
        siteActivities: siteActivitiesAsProto,
      }
    );

    const response =
      await socioGrpcClient.ssp_back.contact_form.ContactControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, CONTACT_KEYS_MAP);

    insertContactData(data);
  },

  async updateContact(_, contactData) {
    const FIELDS_TO_EXCLUDE = ["id", "$id"];
    const { siteBuildingsAsProto, siteActivitiesAsProto } =
      buildContactRelatedFieldListProto(contactData, FIELDS_TO_EXCLUDE);

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.contact_form.ContactRequest,
      {
        ...contactData,
        siteBuildings: siteBuildingsAsProto,
        siteActivities: siteActivitiesAsProto,
      }
    );
    const response =
      await socioGrpcClient.ssp_back.contact_form.ContactControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, CONTACT_KEYS_MAP);

    insertContactData(data);
  },

  async importContact(_, contactData) {
    const FIELDS_TO_EXCLUDE = ["contact", "id", "$id"];
    const { siteBuildingsAsProto, siteActivitiesAsProto } =
      buildContactRelatedFieldListProto(contactData, FIELDS_TO_EXCLUDE);

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.contact_form.ContactRequest,
      {
        ...contactData,
        siteBuildings: siteBuildingsAsProto,
        siteActivities: siteActivitiesAsProto,
      },
      CONTACT_FIELD_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.contact_form.ContactControllerPromiseClient.importContact(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, CONTACT_KEYS_MAP);

    insertContactData(data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
